import React from 'react';
import AuthContext from '../AuthContext';

import QRCodeOverview from './overview';
import PrintableCode from '../../PrintableCode';
import { makeQRCodeShareURL } from '../../data';
import * as VanillaSharing from 'vanilla-sharing';
import QRCode from 'qrcode';
import data from '../../data';

class QRCodeShare extends React.Component {

    state = {
		code_svg_image: null,
		shareurl: null,
		imgurl: null,
	}

	componentDidMount() {
		const { code } = this.props;
		// QRCode.toString returns an SVG string
		// even though it returns a callback, the operation is entirely synchronous
		let code_svg_image;
		QRCode.toString(
			data.makeQRCodeURL(this.context.account.urlname, code.id),
			(err, svg) => {
				if (svg) {
					code_svg_image = Buffer.from(svg).toString('base64');
				}
			});

		let shareurl = makeQRCodeShareURL(this.context.account.urlname, code);
		let imgurl = PrintableCode.getPrintURLPath(this.context.account.urlname, code.id, code.name);

		this.setState({
			code_svg_image,
			shareurl,
			imgurl,
		});
	}

	static contextType = AuthContext;
   
    render() {

		// wait until we've created the SVG image before rendering
		if (!this.state.code_svg_image) {
			return <div/>
		}

		const { page, code } = this.props;
		const { code_svg_image, imgurl, shareurl } = this.state;
		
        return (
            <div>
                {page.id && 
	                <QRCodeOverview 
	                	page={page}
	                	code={code}
						selected="share"
	                />
				}
				
				<div id="body">
			
					<div id="content">
						
						<div id="code-share">
							
							<legend className="title">Email or Share on Social Media</legend>
								
							<div>
								<p>{'Gain maximum exposure of your Zaura Code and this payment page, by sharing using any of the following methods.'}</p>
																
								<button
									className="button"
									onClick={e => {
	                                    const title = `Help support ${this.context.account.name}`;
	                                    const description = `You can support ${this.context.account.name} by sharing the link below:\r\n`;
	                                    
	                                        VanillaSharing.email({
	                                        url: shareurl,
	                                        description,
	                                        subject: title
	                                    });
	                                }} 
	                            >
	                            	<i className="fas fa-envelope" />
	                            	Email
								</button>
	                        
	                            <button
									className="button facebook"
									onClick={e => {
	                                    VanillaSharing.fbButton({
	                                        url: shareurl,
	                                    });
	                                }} 
	                            >
	                            	<i className="fab fa-facebook-f" />
	                            	Facebook
								</button>
	                          
	                            <button
									className="button linkedin"
									onClick={e => {
	                                    VanillaSharing.linkedin({
	                                        url: shareurl,
	                                    });
	                                }} 
	                            >
	                            	<i className="fab fa-linkedin-in" />
	                            	LinkedIn
								</button>
	                           
	                            <button
									className="button twitter"
									onClick={e => {
	                                    VanillaSharing.tw({
	                                        url: shareurl,
	                                    });
	                                }} 
	                            >
	                            	<i className="fab fa-twitter" />
	                            	Twitter
								</button>
							</div>	
							
							<hr />
							
							<legend className="title">Download Raw Zaura Code</legend>

							<p>{`If you'd like to use your Zaura Code on your own digital or printed materials, please download a copy below.`}</p>
							
							<a 
								href={imgurl} 
								target="_blank" 
								rel="noopener noreferrer"
								className="image"
							>
								<div style={{
									height: 236,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									backgroundImage: `url('data:image/svg+xml;base64,${code_svg_image}')`,
								}}/>
							</a>

							<a 
								href={imgurl} 
								target="_blank" 
								rel="noopener noreferrer"
								className="button"
							>
								Download
							</a>
						</div>														
					</div>
				</div>
            </div>
        );
    }
}

export default QRCodeShare;