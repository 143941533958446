import React from 'react';

/**
 * AuthContext is used to provide global user and account data throughout the app
 */
const AuthContext = React.createContext({
    user: null,
    account: null,
});

export default AuthContext;
