import React from 'react';
import axios from '../../config/axios';
import { Table, Thead, Th, Tr, Td, Numeric } from 'reactable';
import { Link } from 'react-router-dom';
import AuthContext from '../AuthContext';
import PaymentPageDetails from './details';
import PaymentPageCodes from './codes';
import PaymentPagePayments from './payments';

import QRCodeDetails from '../QRCodes/details';
import QRCodeShare from '../QRCodes/share';

import firebase from '../../firebase';
import QRCode from 'qrcode';

import Field from '../Components/field';
import OverviewColumn from '../Components/overview';
import SplitButton from '../Components/split_button';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

import data from '../../data';
import { getAccountCurrency } from '../../utils';

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

class PaymentPages extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			/** page ID */
			page: null,
			/** @type {'details'|'codes'|'payments'} */
			section: null,
			/** QR code ID */
			section_id: null,
			/**  @type {'create'|'new'|'share'|'details'} */
			action: null,

			pages: null,
			codes: null,
			transactions: null,
			filter: '',
			submitting: false
		};
		this.dbUnsubscribe = null;
		this.dbUnsubscribeCodes = null;
		this.dbUnsubscribeTxs = null;
	}

	static contextType = AuthContext;

	static get menu() {
		return {
			text: 'Payment Pages',
			link: '/pages',
			icon: 'fas fa-file'
		};
	}

	componentDidMount() {
		const { id, section, section_id, action } = this.props.match.params;

		this.handleUriChange(id, section, section_id, action);

		this.dbUnsubscribe = firebase
			.firestore()
			.collection('pages')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const pages = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}));
				this.setState({
					pages
				});
			});

		this.dbUnsubscribeCodes = firebase
			.firestore()
			.collection('codes')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const codes = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}));
				this.setState({
					codes
				});
			});

		this.dbUnsubscribeTxs = firebase
			.firestore()
			.collection('transactions')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const transactions = snapshot.docs
					.map(doc => ({
						id: doc.id,
						...doc.data()
					}))
					.sort((a, b) =>
						// @ts-ignore Property 'created' does not exist on type '{ id: string; }'
						b.created.localeCompare(a.created)
					); // sort by most-recent first
				this.setState({
					transactions
				});
			});
	}

	componentDidUpdate(prevProps) {
		ReactTooltip.rebuild();

		const {
			match: {
				params: { page_id, section, section_id, action }
			}
		} = this.props;

		if (
			prevProps.match.params.page_id !== page_id ||
			prevProps.match.params.section !== section ||
			prevProps.match.params.section_id !== section_id ||
			prevProps.match.params.action !== action
		) {
			this.handleUriChange(page_id, section, section_id, action);
		}
	}

	componentWillUnmount() {
		if (typeof this.dbUnsubscribe === 'function') {
			this.dbUnsubscribe();
			this.dbUnsubscribe = null;
		}

		if (typeof this.dbUnsubscribeCodes === 'function') {
			this.dbUnsubscribeCodes();
			this.dbUnsubscribeCodes = null;
		}

		if (typeof this.dbUnsubscribeTxs === 'function') {
			this.dbUnsubscribeTxs();
			this.dbUnsubscribeTxs = null;
		}
	}

	handleUriChange(page_id, section, section_id, action) {
		this.setState({
			page: page_id,
			section,
			section_id,
			action
		});
	}

	handleFilterChange(e) {
		this.setState({ filter: e.target.value });
	}

	async savePage(action, page) {
		const {
			id,
			name,
			notes,
			productID,
			description,
			mode,
			prices,
			qrcodes,
			allowUserChosenAmount
		} = page;

		this.setState({ submitting: true });

		const page_data = {
			account: this.context.account.id,
			uid: this.context.user.uid,
			id,
			name,
			notes,
			productID,
			description,
			mode,
			prices,
			qrcodes,
			allowUserChosenAmount
		};

		/** @type {{id: string}}  */
		let page_res;
		try {
			page_res = await axios
				.post('/paymentPage', {
					action,
					data: page_data
				})
				.then(res => res.data);
		} catch (err) {
			Toast.fire({
				type: 'error',
				title: `Payment page save failed. ${err.message}`
			});
			this.setState({ submitting: false });
			return;
		}

		if (action === 'add') {
			// automatically add a QR code for the new payment page
			const code_res = await axios
				.post('/code', {
					action,
					data: {
						account: this.context.account.id,
						uid: this.context.user.uid,
						paymentPage: page_res.id,
						name,
						notes: ''
					}
				})
				.then(res => res.data);
			// QRCode.toString returns an SVG string
			// even though it returns a callback, the operation is entirely synchronous
			let code_svg_image;
			QRCode.toString(
				data.makeQRCodeURL(this.context.account.urlname, code_res.id),
				(err, svg) => {
					if (svg) {
						code_svg_image = Buffer.from(svg).toString('base64');
					}
				}
			);

			Swal.fire({
				title: 'Payment Page Added',
				type: 'success',
				html:
					'<p>Your payment page has been created and can be accessed by scanning this Zaura Code:</p>' +
					`<div style="height: 138px;margin:8px;background-size: contain; background-repeat: no-repeat; background-position: center;background-image: url('data:image/svg+xml;base64,${code_svg_image}');"></div>` +
					'<p>You can view, download and share this code from your payment page settings.</p>',
				showCloseButton: false,
				focusConfirm: true,
				confirmButtonText: '<i class="fa fa-thumbs-up"></i>\xa0OK'
			});

			this.props.history.push(`/pages`);
			this.setState({ submitting: false });
			return;
		}

		Toast.fire({
			type: 'success',
			title: 'Payment page updated'
		});

		this.props.history.push(`/pages`);
		this.setState({ submitting: false });
	}

	render() {
		// don't render anything until we've retrieved all the data
		if (!this.state.pages || !this.state.codes || !this.state.transactions) {
			return <div />;
		}

		// divide the data by payment page
		const pageData = new Map(); // <pageId, {codes, transactions}>
		for (let page of this.state.pages) {
			const codes = this.state.codes.filter(
				code => code.paymentPage === page.id
			);
			const transactions = this.state.transactions.filter(
				tx => tx.paymentPage === page.id
			);
			pageData.set(page.id, {
				codes,
				transactions
			});
		}

		const currency = getAccountCurrency(this.context.account);

		if (this.state.page) {
			if (/new|create/.test(this.state.page)) {
				return (
					<PaymentPageDetails
						page={null}
						codes={[]}
						onSave={page => this.savePage('add', page)}
						submitting={this.state.submitting}
					/>
				);
			}

			const page = this.state.pages.find(page => page.id === this.state.page);

			switch (this.state.section) {
				case 'codes':
					if (/^(new|create)$/.test(this.state.section_id)) {
						return (
							<QRCodeDetails
								page={page}
								code={null}
								submitting={this.state.submitting}
							/>
						);
					}
					if (this.state.section_id) {
						const code = this.state.codes.find(
							code => code.id === this.state.section_id
						);
						switch (this.state.action) {
							case 'share':
								return (
									<QRCodeShare
										page={page}
										code={code}
										submitting={this.state.submitting}
									/>
								);
							case 'details':
							default:
								return (
									<QRCodeDetails
										page={page}
										code={code}
										submitting={this.state.submitting}
									/>
								);
						}
					}
					return (
						<PaymentPageCodes
							page={page}
							codes={pageData.get(page.id).codes}
							transactions={pageData.get(page.id).transactions}
						/>
					);

				case 'payments':
					return (
						<PaymentPagePayments
							page={page}
							codes={pageData.get(page.id).codes}
							transactions={pageData.get(page.id).transactions}
						/>
					);

				case 'details':
				default:
					return (
						<PaymentPageDetails
							page={page}
							codes={pageData.get(page.id).codes}
							transactions={pageData.get(page.id).transactions}
							onSave={page => this.savePage('update', page)}
							submitting={this.state.submitting}
						/>
					);
			}
		}

		return (
			<div>
				<OverviewColumn
					title='Payment Pages'
					content={`Payment pages enable your customers to make payments for a single product or for a set of products. You can tailor each page with branding and payment options and link them to Zaura Codes.`}
					filter={{
						title: 'Filter Pages',
						icon: '',
						fields: (
							<div>
								<Field
									type='text'
									label='Keyword'
									value={this.state.filter}
									onChange={this.handleFilterChange.bind(this)}
									placeholder='Enter keyword'
								/>
								<Field
									label='Status'
									type='select'
									options={[
										{
											key: 'all',
											value: 'all',
											label: 'All'
										},
										{
											key: 'live',
											value: 'live',
											label: 'Live'
										},
										{
											key: 'test',
											value: 'test',
											label: 'Test'
										}
									]}
								/>
							</div>
						)
					}}
					{...this.props}
				/>

				<div id='body'>
					<div id='content'>
						<Table
							className='data-table pages actions'
							sortable={[
								{
									column: 'title',
									direction: 'asc',
									sortFunction: function(a, b) {
										a = a.toLowerCase();
										b = b.toLowerCase();
										return a === b ? 0 : a > b ? 1 : -1;
									}
								},
								{
									column: 'codes',
									sortFunction: Numeric
								},
								{
									column: 'payments',
									sortFunction: Numeric
								},
								{
									column: 'revenue',
									sortFunction: Numeric
								}
							]}
							defaultSort={{
								column: 'title',
								direction: 'asc'
							}}
							filterable={['title']}
							filterBy={this.state.filter}
							hideFilterInput
							noDataText='Get started by pressing Add New Page'
							itemsPerPage={20}
							pageButtonLimit={5}
						>
							<Thead>
								<Th column='title'>Title</Th>
								<Th column='codes'>{'Zaura\xa0Codes'}</Th>
								<Th column='payments'>Payments</Th>
								<Th column='revenue'>Revenue</Th>
								<Th column='actions'>Actions</Th>
							</Thead>
							{this.state.pages
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(page => (
									<Tr key={page.id}>
										<Td column='title' value={page.name}>
											<div>
												<i
													className={`status ${
														page.mode === 'test' ? 'inactive' : ''
													}`}
													data-tip={page.mode === 'test' ? 'Test' : 'Live'}
												/>
												<a
													href={data.makePaymentPageURL(
														this.context.account.urlname,
														page.id
													)}
													target='_blank'
													rel='noopener noreferrer'
													data-tip='View payment page'
												>
													{page.name}
												</a>
											</div>
										</Td>
										<Td column='codes'>
											<NumberFormat
												value={pageData.get(page.id).codes.length}
												displayType={'text'}
												thousandSeparator={true}
											/>
										</Td>
										<Td column='payments'>
											<NumberFormat
												value={pageData.get(page.id).transactions.length}
												displayType={'text'}
												thousandSeparator={true}
											/>
										</Td>
										<Td column='revenue'>
											<NumberFormat
												value={pageData
													.get(page.id)
													.transactions.reduce(
														(sum, tx) => (sum += tx.amount),
														0
													)
													.toFixed(2)}
												displayType={'text'}
												thousandSeparator={true}
												prefix={currency.symbol}
											/>
										</Td>
										<Td column='actions'>
											<SplitButton
												primary={{
													label: 'Edit',
													url: `/pages/${page.id}`
													//onClick: () => { this.setState({ mode: `details:${page.id}` }) }
												}}
												secondary={[
													{
														label: 'View Page',
														href: data.makePaymentPageURL(
															this.context.account.urlname,
															page.id
														)
													},
													{
														label: 'Zaura Codes',
														url: `/pages/${page.id}/codes`
													},
													{
														label: 'Payments',
														url: `/pages/${page.id}/payments`
													},
													{
														label: 'Delete',
														className: 'warning',
														onClick: () => {
															Swal.fire({
																title: 'Delete Payment Page',
																text: `New transactions made on this payment page will not be accepted and any Zaura Codes linked to this page will no longer work. Are you sure you want to delete '${page.name}'?`,
																type: 'warning',
																showCancelButton: true,
																confirmButtonText: 'Yes, delete it!',
																focusConfirm: false
															}).then(result => {
																if (result.value) {
																	axios
																		.post('/paymentPage', {
																			action: 'delete',
																			data: [page.id]
																		})
																		.then(
																			res => {
																				return {
																					type: 'success',
																					title: 'Payment page deleted'
																				};
																			},
																			err => {
																				return {
																					type: 'error',
																					title: `Payment page deleted failed. ${err.message}`
																				};
																			}
																		)
																		.then(toastInfo => {
																			// @ts-ignore Type 'string' is not assignable to type 'SweetAlertType'
																			Toast.fire(toastInfo);
																			this.setState({ mode: '' });
																		});
																}
															});
														}
													}
												]}
											/>
										</Td>
									</Tr>
								))}
						</Table>
						<ReactTooltip multiline={true} />

						<div id='cta' className='overview'>
							<Link className='button' to='/pages/create'>
								Add New Page
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentPages;
