import React from 'react';
import OverviewColumn from '../Components/overview';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import StatBox from '../Components/stat_box';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import AuthContext from '../AuthContext';
import firebase from '../../firebase';
import { formatCurrency, getAccountCurrency, getCurrencySymbol } from '../../utils';

function sumOfTxAmounts(txs) {
	return txs.reduce((sum, tx) => sum += tx.amount, 0);
}

class Home extends React.Component {

    static get menu() {
        return {
            text: 'Dashboard',
            link: '/',
            icon: 'fas fa-home'
        }
    }

	static contextType = AuthContext;

	constructor(props) {
		super(props);
		this.state = {
			codes: null,
			pages: null,
			transactions: null,
		}
		this.dbUnsubscribePages = null;
		this.dbUnsubscribeCodes = null;
		this.dbUnsubscribeTxs = null;
	}

	componentDidMount() {

		// redirect to the account section if no business name has been configured
		if (!this.context.account.name) {
			this.props.history.push('/account/business');
			return;
		}
 
		this.dbUnsubscribePages = firebase.firestore()
			.collection('pages')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const pages = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data(),
				}));
				this.setState({
					pages,
				})
			}
			);

		this.dbUnsubscribeCodes = firebase.firestore()
			.collection('codes')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const codes = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data(),
				}));
				this.setState({
					codes,
				})
			}
			);

		this.dbUnsubscribeTxs = firebase.firestore()
			.collection('transactions')
			.where('account', '==', this.context.account.id)
			.onSnapshot(snapshot => {
				const transactions = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data(),
				}))
					// sort by most-recent first
					.sort((a, b) => (
						// @ts-ignore Property 'created' does not exist on type '{ id: string; }'
						b.created.localeCompare(a.created)
					));

				this.setState({
					transactions,
				})
			});
	}

	componentWillUnmount() {

		if (typeof this.dbUnsubscribePages === 'function') {
			this.dbUnsubscribePages();
			this.dbUnsubscribePages = null;
		}

		if (typeof this.dbUnsubscribeCodes === 'function') {
			this.dbUnsubscribeCodes();
			this.dbUnsubscribeCodes = null;
		}

		if (typeof this.dbUnsubscribeTxs === 'function') {
			this.dbUnsubscribeTxs();
			this.dbUnsubscribeTxs = null;
		}
	}

	render() {

		const { codes, pages, transactions } = this.state;
		if (!codes || !pages || !transactions) {
			return <div/>
		}
	    
	    const colors = [
			'#e6194B',
			'#3cb44b',
			'#f58231'
		];

		const { user } = this.context;
		const currency = getAccountCurrency(this.context.account);

		// Extract the transactions from the last 7 days.
		const one_day_in_millis = 24 * 60 * 60e3;
		const seven_days_ago = Math.trunc((Date.now() - (7 * one_day_in_millis)) / one_day_in_millis) * one_day_in_millis;
		const time_limit_iso = new Date(seven_days_ago).toISOString();
		// ISO-formatted dates (yyyy-mm-ddThh:mm:ss) can be compared as strings
		const last_7_days_txs = transactions.filter(tx => tx.created >= time_limit_iso);

		const paymentPageIDs = new Set();
		const txs_by_date = [];
		let busiest_day = null;
		for (let i=1; i <= 7; i++) {
			const day = new Date(seven_days_ago + (i * one_day_in_millis));
			// create a date string (no time part) in ISO format
			const date = day.toISOString().split('T')[0];
			// find the transactions that match the date
			const day_txs = last_7_days_txs.filter(tx => tx.created.startsWith(date));
			// group the transactions by payment page
			const pp_txs_map = new Map();
			for (let day_tx of day_txs) {
				let pp_txs = pp_txs_map.get(day_tx.paymentPage);
				if (pp_txs) continue;
				// map the paymentPage ID to the set of transactions matching that page
				pp_txs_map.set(day_tx.paymentPage, day_txs.filter(tx => tx.paymentPage === day_tx.paymentPage));
				paymentPageIDs.add(day_tx.paymentPage);
			}
			const day_tx_info = {
				date: day,
				totalPaymentCount: day_txs.length,
				totalRevenue: sumOfTxAmounts(day_txs),
				pp_txs_map,
			}
			// busiest day is the day with the highest number of individual payments
			if (!busiest_day || (day_tx_info.totalPaymentCount > busiest_day.totalPaymentCount)) {
				busiest_day = day_tx_info;
			}
			txs_by_date.push(day_tx_info);
		}

        const paymentStats = {
	        pages: pages.length,
	        payments: transactions.length,
	        revenue: sumOfTxAmounts(transactions),
	        graph: {
				labels: txs_by_date.map(entry => (
					entry.date.toISOString()
				)),
				datasets: [
					{
						label: 'Total',
						data: txs_by_date.map(entry => entry.totalRevenue),
						type: 'line', 
						borderColor: '#3D97D8',
						backgroundColor: '#3D97D8',
						borderWidth: 2,
						fill: false,
					},
					...[...paymentPageIDs].map((paymentPage, i) => {

						// retrieve the page associated with the transactions
						const page = pages.find(page => page.id === paymentPage);

						return {
							label: page && page.name,
							data: txs_by_date.map(entry => {
								const txs = entry.pp_txs_map.get(paymentPage) || [];
								return sumOfTxAmounts(txs);
							}),
							borderColor: '#FFFFFF',
							backgroundColor: colors[i],
						}
					})
				]
			},
			transactions: transactions.slice(0, 5).map(tx => {
				const page = pages.find(p => p.id === tx.paymentPage) || {};
				const currencyCode = tx.currency || 'gbp';
				return {
					amount: tx.amount.toFixed(2),
					currency: {
						code: currencyCode,
						symbol: getCurrencySymbol(currencyCode),
					},
					date: tx.created,
					id: tx.id,
					page_id: page.id,
					page: page.name,
				}
			}),
        };
        
        return (
        	<div id="dashboard">
               	<OverviewColumn 
					title={`Hello ${user.displayName || user.email}`}
					content={`Welcome to your Zaura account.`}
			    />
                           
				<div id="body">
			
					<div id="content">
						
						<legend className="title">
							<i className="far fa-calendar"></i>
							7 Day Activity
						</legend>
						
						<div className="stats">
							
							<StatBox
								title="Total Payments"
								value={paymentStats.payments}
							/>
							
							<StatBox
								title="Total Revenue"
								value={formatCurrency(paymentStats.revenue)}
								currency={currency}
							/>
							
							{busiest_day && <StatBox
								title="Busiest Day"
								value={moment(busiest_day.date).format('ddd Do MMM')}
								summary={`${busiest_day.totalPaymentCount} payments`}
							/>}
						</div>
						
						<br/><br/>
						
						<div className="chart-responsive">
							<Bar 
								data={{
									labels: paymentStats.graph.labels,
									datasets: paymentStats.graph.datasets
								}} 
								options={{
									tooltips: {
										mode: 'index',
										intersect: false,
										callbacks: {
								            title: function(tooltipItem, data){						            		
							            		return tooltipItem[0].label;
								            },
								            label: function(tooltipItem, data) {
												return `${data.datasets[tooltipItem.datasetIndex].label}: ${currency.symbol}${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString(undefined, {maximumFractionDigits:2})}`;
											}
							            }
									},
									legend: {
							            display: false
							        },
									responsive: true,
									maintainAspectRatio: false,
									scales: {
										xAxes: [{
											stacked: true,
											ticks: {
							                    callback: function(value, index, values) {
							                        return moment(value).format('ddd DD/MM');
							                    }
                							}
										}],
										yAxes: [{
											stacked: true,
											ticks: {
							                    callback: function(value, index, values) {
							                        return `${currency.symbol}${value.toLocaleString(undefined, {maximumFractionDigits:2})}`;
							                    }
                							}
										}]
									}
								}}
							/>
						</div>
						
						<hr />
						
						<div className="split">
						
							<div className="splitted overview">
								<legend className="title">
									<i className="fas fa-asterisk"></i>
									Overview
								</legend>
								
								<div className="stats secondary">
									
									<StatBox
										url="/transactions"
										title="Total Payments"
										value={paymentStats.payments}
									/>
									
									<StatBox
										url="/transactions"
										title="Total Revenue"
										value={formatCurrency(paymentStats.revenue)}
										currency={currency}
									/>
						
									<StatBox
										url="/pages"
										title="Total Pages"
										value={paymentStats.pages}
									/>
								</div>
							</div>
							<div className="splitted transactions">
								
								<legend className="title">
									<i className="fas fa-list-alt"></i>
									Latest Transactions
								</legend>
								
								<Table 
									className="data-table"
								>							
				                    <Thead>
			                            <Th column="date">Date</Th>
			                            <Th column="page">Payment Page</Th>
			                            <Th column="amount">Amount</Th>
				                    </Thead>
			                        {paymentStats.transactions.map(t => {
		
			                            return (
				                            <Tr key={t.id}>
				                                <Td column="date" value={t.date} className="nowrap">
					                               	<Link to={`/transactions?id=${t.id}`}>
						                               {moment(t.date).format('DD/MM/YYYY hh:mm')}
						                            </Link>
				                                </Td>
				                                <Td column="page">
					                                <Link to={`/transactions?id=${t.page_id}`}>
						                                {t.page}
						                            </Link>
						                        </Td>
				                                <Td column="amount">
					                                <Link to={`/transactions?id=${t.id}`}>
											            <NumberFormat 
															value={t.amount} 
															displayType={'text'}
															thousandSeparator={true}
															prefix={t.currency.symbol}
														/>
											        </Link>
					                            </Td>
				                            </Tr>
			                            )
			                        })}
				                </Table>
				                
				                <Link to="/transactions" className="button">
		                            View All Transactions
								</Link>
							</div>
						</div>
									
					</div>
					
					
				</div>
            </div>
	    );
    }
}

export default Home;
