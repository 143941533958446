import React from 'react';
import { withRouter } from "react-router-dom";
import OverviewColumn from '../Components/overview';
import Swal from 'sweetalert2';

class AccountOverview extends React.Component {

    render() {
        
        const { account, selected } = this.props;

        return (
        	<OverviewColumn 
		    	title={account.name || 'My Account'}
				content="Welcome to your account area where you can fully control and customise your account settings and preferences."
				links={[
					{
						label: 'Account Settings',
						url: '/account',
						selected: (selected === 'account' ? true : false)
					},
					{
						label: 'Business Settings',
						url: '/account/business',
						selected: (selected === 'business' ? true : false)
					},
					{
						label: 'Payment Settings',
						url: '/account/stripe',
						selected: (selected=== 'stripe' ? true : false)
					},
					/*{
						label: 'Close Account',
						url: '/account/close',
						selected: (selected=== 'close' ? true : false)
					}*/
					{
						label: 'Log Out',
						url: '#',
						onClick: () => {
													
							Swal.fire({
								title: 'Are you sure?',
								type: 'warning',
								showCancelButton: true,
								confirmButtonText: 'Yes',
								focusConfirm: false
							}).then((result) => {
								
								if (result.value) {
									this.props.history.push('/logout');
								}
							});
						}
					}
				]}
		    />
		);
    }
}

export default withRouter(AccountOverview);