import React from 'react';

class Submit extends React.Component {

    render() {	    
		
		let label = this.props.label;
		let type = 'button';
		
		if(this.props.submit){
			type = 'submit';
		}
		
		if(this.props.submitting){
			
			label = (
				<i className="fas fa-circle-notch fa-spin" style={{padding:'1em 30px'}}></i>
			);
		}	
		
        return (
			<button 
				type={type}
				className="button"
				onClick={this.props.onClick ? () => { this.props.onClick() } : null}
				disabled={this.props.submitting || this.props.disabled}
			>
				{label}
			</button>
        );
    }
}
export default Submit