import React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

class StatBox extends React.Component {

    componentDidUpdate(prevProps) {
		ReactTooltip.rebuild();
	}
	
	render() {	  
	    
	    let { value, currency } = this.props;
	    let last = null;
	    
	    if(currency){
		    value = <NumberFormat 
				value={value} 
				displayType={'text'}
				thousandSeparator={true}
				prefix={currency.symbol}
			/>
	    }  
	    
	    if(this.props.last){
		    
		    let difference = this.props.value-this.props.last.value;
		    			    
		    if(difference !== 0){
			    
			    let lastValue = this.props.last.value;
			    let arrow = 'up';
			    
			    if(difference < 0){
				    arrow = 'down';
			    }
			    
			    if(currency){
				    				    
				    difference = <NumberFormat 
						value={difference} 
						displayType={'text'}
						thousandSeparator={true}
						prefix={currency.symbol}
					/>
					
					lastValue = `${currency.symbol}${lastValue.toLocaleString(undefined, {maximumFractionDigits:2})}`;
			    } 
			    			    
			    last = (
				    <div data-tip={this.props.last.tooltip.replace('[VALUE]', lastValue)} className={`difference ${arrow}`}>
					    <i className={`fas fa-arrow-${arrow}`}></i>
					    {difference}
					    <ReactTooltip 
							multiline={true}
						/>
					</div>
			    );
		    }
		}

		if (!this.props.url) {
			return <div className="box">
				<label>{this.props.title}</label>
				<strong>{value}</strong>
				{last}

				{this.props.summary && !last &&
					<div className="summary">
						{this.props.summary}
					</div>
				}
			</div>
		}
			    
        return (
            <Link 
				to={this.props.url}
				className="box"
			>
				<label>{this.props.title}</label>
				<strong>{value}</strong>
				{last}
				
				{this.props.summary && !last && 
					<div className="summary">
						{this.props.summary}
					</div>
				}
			</Link>
        );
    }
}
export default StatBox;