import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class OverviewColumn extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
            navOpen: false
        };
    }

	toggleNav = () => {
        this.setState(state => ({ navOpen: !state.navOpen }));
    };
    
    openNav = () => {
        this.setState(state => ({ navOpen: true }));
    };
    
    render() {
	            
        return (
            <div id="overview" className={this.state.navOpen ? 'open' : null}>
				<div id="title">
					<h1 onClick={this.openNav}>{this.props.title}</h1>
					
					{this.props.back &&
						<Link 
						    to={this.props.back.url ? this.props.back.url : '#'} 
							onClick={this.props.back.onClick ? () => { this.props.back.onClick() }: null}
							data-tip={`Back to ${this.props.back.label}`}
						>
					    	{this.props.back.label}
					    	
					    	<ReactTooltip 
								multiline={true}
							/>
					    </Link>
					}
				</div>
				
				<div id="toggle" onClick={this.toggleNav}></div>
				
				<div id="info">
					<p>{this.props.content}</p>
					
					{this.props.links && 
						<ul>
						    {this.props.links.map(link => (
				                <li key={link.label} className={link.selected ? 'selected' : ''}>
									<Link to={link.url} onClick={link.onClick ? () => { link.onClick() }: null}>
										{link.label} 
										{link.counter ? (<span>{link.counter}</span>) : ''}
									</Link>
								</li>
				            ))}
			            </ul>
				    }
				    
				    {this.props.filter && 
						<fieldset>
					        <h5>
						        <i className={`fas fa-${this.props.filter.icon ? this.props.filter.icon : 'search'}`}></i>
						        {this.props.filter.title}
					        </h5>
						     {this.props.filter.fields}
						</fieldset>
				    }
				</div>
			</div>
        );
    }
}
export default OverviewColumn;
