import React from 'react';
import OverviewColumn from '../Components/overview';

class QRCodeOverview extends React.Component {
    
    render() {
        
        const { page, code, selected } = this.props;
        
        return (
        	<OverviewColumn 
	        	title={code.name}
				back={{
					url: `/pages/${page.id}/codes`, 
					label: `Zaura Codes for ${page.name}`
				}}
				content="Your unique Zaura Code can be managed here."
				links={[
					{
						label: 'Zaura Code Details',
						url: `/pages/${page.id}/codes/${code.id}`,
						selected: (selected === 'details' ? true : false)
					},
					{
						label: 'Share',
						url: `/pages/${page.id}/codes/${code.id}/share`,
						selected: (selected === 'share' ? true : false)
					}
				]}
	        />
		);
    }
}

export default QRCodeOverview;