import React from 'react';
import auth from '../auth';
import Field from '../Main/Components/field';
import Submit from '../Main/Components/submit';
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

class Welcome extends React.Component {
    
    state = {
        user: [],
        errorFields: {},
        submitting: false
    }
    
    handleChange(which) {
        return e => this.setState(
            {
                user: {
                    ...this.state.user,
                    [which]: e.target.value,
                },
                errorFields: {
                    ...this.state.errorFields,
                    // when a field is edited, clear any error state
                    [which]: null,
                },
            });
    }
    
    validateLogin() {
        
        let user = this.state.user;
        
        // trim the free-text fields
        for (let i in Object.keys(user)) {
            if (typeof user[i] === 'string') {
                user[i] = user[i].trim();
            }
        }
        const errorFields = {};
        
        if (!user.email) {
            errorFields.email = 'Email address cannot be blank.';
        }
        
        if (!user.password) {
            errorFields.password = 'Password cannot be blank.';
        }
        
        if (Object.keys(errorFields).length) {
            this.setState({ errorFields })
            return false;
        }
        return true;
    }

    render() {
        return (
        	<div id="auth">
	        	
	        	<img src="/images/logo.png" alt="Zaura" />
	        	
	        	<form 
		        	id="login"
		        	onSubmit={e => {
                            
                            e.preventDefault();
	                            
		                    if(this.validateLogin()) {
                                            
	                            this.setState({ submitting: true });
			                        
		                        const { email, password } = this.state.user;
						        
						        auth.authenticate(email, password, false)
						            .catch(error => {
						                
						                let message;
						                
						                switch (error.code) {
								            case 'auth/user-not-found':
								            case 'auth/wrong-password':
								                message = 'Wrong email address or password';
								                break;
								            default:
								                message = error.message;
								                break;
								        }
								        
								        Toast.fire({
											type: 'error',
											title: message
										});
										
										this.setState({ submitting: false });
						            }
						        ); 
						    }                        
                        }}
			    >
					
					<h1>Account Login</h1>
					
		            <fieldset>
			            <Field 
							label="Email"
							type="email"
							id="email"
							required={true}
							disabled={this.state.submitting}
							value={this.state.user.email}
	                        onChange={this.handleChange('email')}
	                        error={this.state.errorFields.email}
	                        autoFocus={typeof window.orientation === "undefined"}
						/>
						
						<Field 
							label="Password"
							type="password"
							id="password"
							required={true}
							disabled={this.state.submitting}
							value={this.state.user.password}
	                        onChange={this.handleChange('password')}
	                        error={this.state.errorFields.password}
						/>
					</fieldset>

		            <div className="actions">
			            <Submit 
							label="Login"
							submit={true}
	                        submitting={this.state.submitting}
						/>
					</div>
		        </form>
		        
		        <div id="swap">
			        <a href="mailto:support@zaura.com">
				        support@zaura.com
				    </a>
			    </div>
        	</div>
        );
    }
}

export default Welcome;
