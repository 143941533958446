import React from "react";
import config from '../config';

class PrintableCode extends React.Component {

    static getPrintURLPath(urlname, code, name) {
        return `/?print=${urlname}-${code}-${encodeURIComponent(name)}`;
    }

    /**
     * @param {URLSearchParams} params
     */
    static decodePrintURLPath(params) {
        const printid = params && params.get("print");
        if (!printid) {
            return null;
        }
        // the URL should be formatted as /print?<urlname>-<qrcodeid>
        const m = printid.match(/^([a-z\d-]+)-(\w+)-([^-]+)$/);
        if (!m) {
            return null;
        }
        let name;
        try {
            name = decodeURIComponent(m[3]).trim().replace(/\s+/g,'-').replace(/[^\w-]/g, '');
        } catch(err) {
        }
        return {
            urlname: m[1],
            code: m[2],
            name,
        };
    }

    render() {
        return <div style={{display:'flex', marginTop:'1em'}}>
            <img
                src={`https://${config.paymentDomain}/qrcode/${this.props.code}/${this.props.name || 'code'}-${this.props.code}.png`}
                alt="Zaura Code"
                style={{width:'30em', height:'30em', margin:'0 auto', imageRendering:'pixelated'}}
            />
        </div>
    }

}

export default PrintableCode;
