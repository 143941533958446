// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
const firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require('firebase/storage');

// load the firebase info from the build-dependent config
const config = require('./config');
firebase.initializeApp(config.firebaseConfig);

export default firebase;
