import React from 'react';
import AuthContext from '../AuthContext';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import StatBox from '../Components/stat_box';

import PageOverview from './overview';
import { formatCurrency, getAccountCurrency } from '../../utils';

// number of days to display stats/graph for
const stats_day_count = 30;

class PaymentPagePayments extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.breakdownTransactions();
    }
   
	static contextType = AuthContext;
	
    componentDidUpdate() {
		if (this.state.totalPaymentCount === this.props.transactions.length) {
			return;
		}
		this.setState(this.breakdownTransactions())
	}

	breakdownTransactions() {
		const { transactions } = this.props;
		const totalRevenue = transactions.reduce((total, tx) => total += tx.amount, 0);

		const one_day_in_millis = 24 * 60 * 60 * 1000;
		// initialise an array for the last n days with paymentCount = 0
		const dailyTransactions = [];
		for (let i = stats_day_count; i >= 0; i--) {
			dailyTransactions.push({
				date_millis: Date.now() - (i * one_day_in_millis),
				date_key: new Date(Date.now() - (i * one_day_in_millis)).toLocaleDateString(),
				paymentCount: 0,
			})
		}
		const time_limit = new Date(Date.now() - (stats_day_count * one_day_in_millis)).toISOString();
		let recentRevenue = 0;
		let recentPaymentCount = 0;
		for (let tx of transactions) {
			if (tx.created < time_limit) {
				// we've gone past the 30 day limit
				break;
			}
			// construct a date key for this transaction
			const date_key = new Date(tx.created).toLocaleDateString();
			// find a matching date entry in the array
			const entry = dailyTransactions.find(entry => entry.date_key === date_key);
			if (entry) {
				entry.paymentCount += 1;
				recentPaymentCount += 1;
				recentRevenue += tx.amount;
			}
		}

		return {
			dailyTransactions,
			recentRevenue,
			recentPaymentCount,
			totalRevenue,
			totalPaymentCount: transactions.length,
		};
    }

	render() {
		const {
			dailyTransactions,
			recentPaymentCount,
			recentRevenue,
			totalRevenue } = this.state;
		const { page, codes, transactions } = this.props;
		const currency = getAccountCurrency(this.context.account);

		const paymentData = {
	        last: transactions[0] ? new Date(transactions[0].created) : null,
			last_id: transactions[0] ? transactions[0].id : '',
	        graph: {
				// the labels are a set of dates for the last <n> days
				labels: dailyTransactions.map(dt => moment(dt.date_millis).format('DD MMM')),
				datasets: [
					{
						label: 'Payments',
						data: dailyTransactions.map(dt => dt.paymentCount),
						fill: false,
						backgroundColor: '#3D97D8',
						borderColor: '#3D97D8',
						borderWidth: 2
					}
				]
			}
        };
        
        return (
            <div>
               	<PageOverview 
					page={page}
					codes={codes}
					transactions={transactions}
					selected="payments"
                />
                           
				<div id="body">
			
					<div id="content">
						
						<legend className="title">
							<i className="far fa-calendar"></i>
							Last {stats_day_count} days: {moment(dailyTransactions[0].date_millis).format('DD/MM/YYYY')} - {moment(dailyTransactions.slice(-1)[0].date_millis).format('DD/MM/YYYY')}
						</legend>
						
						<div className="stats">
							
							<StatBox
								title="Payments"
								value={recentPaymentCount}
							/>
							
							<StatBox
								title="Revenue"
								value={formatCurrency(recentRevenue)}
								currency={currency}
							/>
							
							{paymentData.last &&
							<StatBox
								title="Last Payment"
								value={moment(paymentData.last).format('Do MMM')}
							/>}
						</div>
						
						<br/><br/>
						
						<div className="chart-responsive">
							<Line 
								data={{
									labels: paymentData.graph.labels,
									datasets: paymentData.graph.datasets
								}} 
								options={{
									tooltips: {
										mode: 'index',
										intersect: false,
										callbacks: {
								            title: function(tooltipItem, data){						            		
							            		return tooltipItem[0].label;
								            }
							            }
									},
									legend: {
							            display: false
							         },
									responsive: true,
									maintainAspectRatio: false,
									scales: {
										xAxes: [{
											stacked: true,
										}],
										yAxes: [{
											display: true,
											ticks: {
												suggestedMax: 10,
												beginAtZero: true   // minimum value will be 0.
											}
										}]
									}
								}}
							/>
						</div>
						
						<hr />
						
						<legend className="title">
							<i className="fas fa-asterisk"></i>
							Since page created: {moment(page.created).format('DD/MM/YYYY')}
						</legend>
						
						<div className="stats secondary">
							
							<StatBox
								title="Total Payments"
								value={transactions.length}
							/>
							
							<StatBox
								title="Total Revenue"
								value={formatCurrency(totalRevenue)}
								currency={currency}
							/>
							
							{paymentData.last && <StatBox
								title="Last Payment"
								value={moment(paymentData.last).format('Do MMM')}
							/>}
						</div>
												
						<div id="cta" className="overview">
							<Link to={`/transactions?page=${page.id}`} className="button">
	                            View All Transactions
							</Link>
						</div>				
					</div>
					
					
				</div>
            </div>
        );
    }
}

export default (PaymentPagePayments);
