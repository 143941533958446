import React, { useEffect } from 'react';
import { Link, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Account from './Account';
import AccountBusiness from './Account/business';
import AccountStripe from './Account/stripe';
import Home from './Home';
import PaymentPages from './PaymentPages';
import Transactions from './Transactions';

import Gravatar from 'react-gravatar';
import auth from '../auth';
import AuthContext from './AuthContext';
import axios from '../config/axios';
import {
	Modal,
	RadioGroup,
	FormControlLabel,
	Radio,
	CircularProgress,
	withStyles
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// Toast
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

class ResponsiveLayout extends React.Component {
	state = {
		navOpen: false,
		page: 'Home',

		// state for reports
		canViewReports: false,
		showLoading: false,
		modal: { state: false },
		reportType: 'summary',
		dateRange: null
	};

	static contextType = AuthContext;

	toggleNav = () => {
		this.setState(state => ({ navOpen: !state.navOpen }));
	};

	closeNav = () => {
		this.setState(state => ({ navOpen: false }));
	};

	toggleLoading = param => {
		this.setState({
			showLoading:
				typeof param !== 'undefined' ? param : !this.state.showLoading
		});
	};

	componentDidMount = async () => {
		await this.canViewReports();
	};

	// download full report
	downloadFullReport = async dateRange => {
		try {
			const response = await axios.post(
				'/reports/transactions',
				{
					uid: this.context.user.uid,
					dateRange
				},
				{ responseType: 'blob' }
			);
			if (response.status !== 200) {
				Toast.fire({
					type: 'error',
					title: response.data.error
				});
			} else {
				this.downloadFile(response);
			}
			this.toggleLoading(false);
		} catch {
			this.toggleLoading(false);
			Toast.fire({
				type: 'error',
				title: 'Something went wrong !'
			});
		}
	};

	// download summary report
	downloadSummaryReport = async dateRange => {
		try {
			const response = await axios.post(
				'/reports/summary',
				{
					uid: this.context.user.uid,
					dateRange
				},
				{ responseType: 'blob' }
			);

			if (response.status !== 200) {
				Toast.fire({
					type: 'error',
					title: response.data.error
				});
			} else {
				this.downloadFile(response);
			}
			this.toggleLoading(false);
		} catch {
			this.toggleLoading(false);
			Toast.fire({
				type: 'error',
				title: 'Something went wrong !'
			});
		}
	};
	downloadFile = response => {
		const url = window.URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'reports.csv'); //or any other extension
		document.body.appendChild(link);
		link.click();
		Toast.fire({
			type: 'success',
			title: 'Downloaded successfully !'
		});
	};
	// check if the user can view reports
	canViewReports = async () => {
		const response = await axios.post('/reports/permission', {
			uid: this.context.user.uid
		});
		if (response.status === 200) {
			const canViewReports = response.data.canAccess;
			this.setState({ canViewReports });
		}
	};

	// toggle modal
	toggleModal = event => {
		event.preventDefault();
		this.setState({ modal: { state: !this.state.modal.state } });
	};

	getDateRange = dateRange => {
		this.setState({ dateRange });
	};
	onChangeReport = event => {
		this.setState({ reportType: event.target.value });
	};
	downloadReport = () => {
		this.toggleLoading();
		if (!this.state.dateRange) {
			this.toggleLoading(false);
			return Toast.fire({
				type: 'error',
				title: 'Date range not selected'
			});
		} else if (
			(this.state.dateRange && !this.state.dateRange.startDate) ||
			(this.state.dateRange && !this.state.dateRange.endDate)
		) {
			this.toggleLoading(false);
			return Toast.fire({
				type: 'error',
				title: ' Start date or End date is missing'
			});
		} else if (
			this.state.dateRange &&
			this.state.dateRange.startDate > this.state.dateRange.endDate
		) {
			this.toggleLoading(false);
			return Toast.fire({
				type: 'error',
				title: 'Start date cannot be greater than End date'
			});
		}
		if (this.state.reportType === 'full') {
			this.downloadFullReport(this.state.dateRange);
		} else if (this.state.reportType === 'summary') {
			this.downloadSummaryReport(this.state.dateRange);
		} else {
			this.toggleLoading(false);
			return Toast.fire({
				type: 'error',
				title: 'Wrong report type selected'
			});
		}
	};
	render() {
		let navSections = [Home, PaymentPages, Transactions];
		if (!this.context.account.name) {
			navSections = [];
		}

		const navLinks = (
			<ul>
				{navSections
					.map(
						sections =>
							// @ts-ignore
							sections.menu
					)
					.map(menu => (
						<li key={menu.text}>
							<Link to={menu.link || '/'} onClick={this.closeNav}>
								<i className={menu.icon} />
								{menu.text}
							</Link>
						</li>
					))}
				{this.state.canViewReports && (
					<li>
						<Link onClick={this.toggleModal}>Reports</Link>
					</li>
				)}

				<li>
					<Link to={Account.menu.link || '/'} onClick={this.closeNav}>
						<Gravatar
							email={this.context.user.email}
							size={60}
							rating='g'
							default='mp'
						/>
						{Account.menu.text}
					</Link>
				</li>
			</ul>
		);

		return (
			<BrowserRouter>
				<header>
					<Link to='/' id='logo'>
						<img src='/images/logo.png' alt='Zaura' />
					</Link>
					<nav className={this.state.navOpen ? 'open' : null}>
						{navLinks}
						<div id='hamburger' onClick={this.toggleNav}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</nav>
				</header>

				<Switch>
					<Route exact path='/' component={Home} />

					<Route
						path='/pages/:page_id?/:section?/:section_id?/:action?'
						component={PaymentPages}
					/>
					<Route exact path='/transactions' component={Transactions} />

					<Route exact path='/account/business' component={AccountBusiness} />
					<Route exact path='/account/stripe' component={AccountStripe} />
					<Route exact path='/account' component={Account} />

					<Route
						exact
						path='/logout'
						render={() => {
							auth.logout();
							return <Redirect to='/' />;
						}}
					/>
				</Switch>
				<Modal
					open={this.state.modal.state}
					onClose={this.toggleModal}
					aria-labelledby='simple-modal-title'
					aria-describedby='simple-modal-description'
				>
					<div className='modal-content'>
						<div className='justify-flex-end'>
							<i className='fas fa-times' onClick={this.toggleModal}></i>
						</div>
						<h1>Reports</h1>
						<RadioGroup
							row
							aria-label='Report Type'
							name='reportType'
							value={this.state.reportType}
							onChange={this.onChangeReport}
						>
							<FormControlLabel
								value='summary'
								control={<ThemeRadio />}
								label='Summary'
							/>
							<FormControlLabel
								value='full'
								control={<ThemeRadio />}
								label='Full Report'
							/>
						</RadioGroup>
						<DateRangePicker getDateValues={this.getDateRange} />
						<button
							disabled={this.state.showLoading}
							className='button mt-8'
							onClick={this.downloadReport}
						>
							{this.state.showLoading ? (
								<CircularProgress size={12} />
							) : (
								'Download'
							)}
						</button>
					</div>
				</Modal>
			</BrowserRouter>
		);
	}
}
const DateRangePicker = ({ getDateValues }) => {
	const [startDate, setStartDate] = React.useState('');
	const [endDate, setEndDate] = React.useState('');
	useEffect(() => {
		getDateValues({ startDate, endDate });
	}, [startDate, endDate]);
	return (
		<div className='justify-space-between mt-4'>
			<TextField
				id='date'
				label='Start Date'
				type='date'
				InputLabelProps={{
					shrink: true
				}}
				onChange={event => {
					setStartDate(new Date(event.target.value).toISOString());
				}}
			/>
			<TextField
				id='date'
				label='End Date'
				type='date'
				defaultValue={new Date().toLocaleDateString()}
				InputLabelProps={{
					shrink: true
				}}
				onChange={event => {
					setEndDate(new Date(event.target.value).toISOString());
				}}
			/>
		</div>
	);
};
const ThemeRadio = withStyles({
	root: {
		color: '#3d97d8',
		'&$checked': {
			color: '#0077b5'
		}
	},
	checked: {}
})(props => <Radio color='default' {...props} />);

ResponsiveLayout.propTypes = {
	// Injected by the documentation to work in an iframe.
	// You won't need it on your project.
	container: PropTypes.object
};

export default ResponsiveLayout;
