import React from 'react';
import Textarea from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import ReactPasswordStrength from 'react-password-strength';

import 'react-password-strength/dist/style.css';

class Field extends React.Component {

    componentDidUpdate() {
		ReactTooltip.rebuild();
	}
	
	render() {
	          
	    const field = this.props;
	    let className = [];
	    
	    if(field.className){
		    className.push(field.className);
		}
		
		if(field.error && field.error.length > 0){
			className.push('error');
		}
		
		let input = null;
		const type = field.type || 'text';
		switch(type){
			
			case 'textarea':
				input = (
					<Textarea 
						id={field.id || null}
						value={field.value || ''}
	                    onChange={field.onChange || null}
	                    autoComplete={field.autoComplete ? 'on' : 'off'}
	              	    className={Array.isArray(className) ? className.join(' ') : null}
						disabled={field.disabled}
					 />	
				);
				break;
				
			case 'select':
				input = (
					<div className="select">
		            	 <select 
							id={field.id || null}
							value={field.selected || ''}
		                    onChange={field.onChange || null}
							className={Array.isArray(className) ? className.join(' ') : null}
							disabled={field.disabled}
						 >	
							{field.options.map(option => (
		                        <option
									key={option.label}
			                        value={option.value}
				                >
									{option.label}
								</option>
							))}
						 </select>
					</div>
				);
				break;
				
			case 'password-strength':
				input = (
					<ReactPasswordStrength
						minLength={field.minLength || 6}
						minScore={field.minScore || 2}
						scoreWords={['weak', 'okay', 'good', 'strong', 'great']}
						changeCallback={field.onChange || null}
						inputProps={{ 
							id: (field.id || null),
							name: "password_input", 
							autoComplete: "off",
							disabled: field.disabled,
						}}
					/>
				);
				break;
				
			case 'text-list':
			
				let selected = '';
				
				if(field.selected.length > 0){
					
					selected = field.selected.sort((a,b) => a-b).map((p, idx) => (
                        <li key={idx}>
							
							{field.currency && 
								<NumberFormat 
									value={p} 
									displayType={'text'}
									thousandSeparator={true}
									prefix={field.currency.symbol}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							}
							
							{!field.currency && 
								<span>{p}</span>
							}
							
							<i 
								className="fas fa-minus-circle"
								onClick={() => {
									field.deleteAction(p)
								}}
								data-tip="Delete"
							/>
						</li>
                    ));
				}
			
				input = (
					<ul className="text-list">
						{selected}
						<li className={`${field.value.length > 0 ? 'value' : ''} ${field.currency ? 'currency' : ''}`}>
							<style dangerouslySetInnerHTML={{
								__html:
									`.value.currency:before {
										content: '${field.currency.symbol}';
									}`
							}}>
							</style>
							<input 
								type="text"
								value={field.value}
			                    placeholder={field.placeholder ? field.placeholder : null}
			                    onKeyDown={(e) => {	                                
	                                
	                                e = e || window.event;
	                                
	                                if(e.key === "Escape" || e.key === "Esc" || e.keyCode === 27){
		                                if(field.onCancel){
			                                field.onCancel();
			                            }
			                            
		                                this.textInput.blur();
	                                }else if (/enter/i.test(e.key)) {
	                                    field.addAction();
	                                }
	                            }}
	                            onChange={field.onChange || null}
	                            ref={input => {
						            this.textInput = input;
						        }}
							 />
							 <button 
								className="button"
								onClick={field.addAction}
							>
								 Add
							 </button>
						</li>
					</ul>
				);
				break;
				
			default: 
				input = (
					<input 
						type={type}
						id={field.id || null}
						value={field.value || ''}
	                    onChange={field.onChange || null}
	                    autoComplete={field.autoComplete ? 'on' : 'off'}
	                    autoFocus={field.autoFocus ? true : null}
	                    className={Array.isArray(className) ? className.join(' ') : null}
	                    placeholder={field.placeholder || null}
						disabled={field.disabled}
					 />
				);
				break;
		}
		
        return (
            <div className="form-element">
				
				{field.label &&
                	<label className={field.required ? 'required' : null}>
	                	{field.label}
	                	
	                	{field.tooltip && 
		                	<span>
			                	<i className="far fa-question-circle help" data-tip={field.tooltip}></i>
								<ReactTooltip 
									multiline={true}
								/>
							</span>
	                	}
	                </label>
                }
	            
				{input}
				 
				<div className="error-reason">
						{field.error || '\u200b'}
				</div>
				
			</div>
        );
    }
}
export default Field;
