import firebase from './firebase';
import axios from './config/axios';

let currentLogin = null;
let dbAccountChangeMonitor = null;
let authObserverCallbacks = new Set();
firebase.auth().onAuthStateChanged(user => {
	const state = {
		user,
		account: null,
		accountError: null
	};
	const callbacks = result => {
		const cbs = [...authObserverCallbacks];
		cbs.forEach(cb => cb(result));
	};
	// turn off the account change listener each time the auth state changes
	if (dbAccountChangeMonitor) {
		dbAccountChangeMonitor();
		dbAccountChangeMonitor = null;
	}

	if (!user || !user.uid) {
		// no current user
		callbacks(state);
		return;
	}

	// choose the API call based on whether Firebase is telling us if this is a new user
	const isNewAccount =
		currentLogin &&
		currentLogin.additionalUserInfo &&
		currentLogin.additionalUserInfo.isNewUser;
	const apicall = isNewAccount
		? axios.post('/account/create', { uid: user.uid })
		: axios.post('/account/get', { uid: user.uid });

	currentLogin = null;

	apicall.then(response => {
		if (!response || !response.data || !response.data.id) {
			// the account doesn't exist
			state.accountError = new Error('The account could not be retrieved.');
			callbacks(state);
			return;
		}
		// login successful - start monitoring the account for live changes
		const account = response.data;
		dbAccountChangeMonitor = firebase
			.firestore()
			.collection('accounts')
			.doc(account.id)
			.onSnapshot(doc => {
				const account = {
					id: doc.id,
					...doc.data()
				};
				state.account = account;
				callbacks(state);
			});
	});
});

function authenticate(email, password, newAccount) {
	const authfn = newAccount
		? 'createUserWithEmailAndPassword'
		: 'signInWithEmailAndPassword';
	return firebase
		.auth()
		[authfn](email, password)
		.then(login => {
			// the auth observer will trigger when this is complete, so tell it
			// that this is a new account
			currentLogin = login;
			// return a new promise that waits for the auth observer to trigger and complete account retrieval
			return new Promise((resolve, reject) => {
				const off = monitorLoginChanges(state => {
					off();
					if (state.accountError) {
						reject(state.accountError);
						return;
					}
					resolve();
				});
			});
		});
}

function monitorLoginChanges(callback) {
	if (callback) {
		// add the callback to the auth observer set
		authObserverCallbacks.add(callback);
	}
	// return a function that allows the caller to stop monitoring
	return () => authObserverCallbacks.delete(callback);
}

function logout() {
	firebase.auth().signOut();
}

export default {
	authenticate,
	logout,
	monitorLoginChanges
};
