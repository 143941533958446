import React from 'react';

import { CircularProgress } from '@material-ui/core';
import AuthContext from '../AuthContext';
import firebase from '../../firebase';

import AccountOverview from './overview';
import Field from '../Components/field';
import Submit from '../Components/submit';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

class Account extends React.Component {
	dbUnsubscribe = null;

	constructor(props) {
		super(props);
		this.state = {
			account: null,
			submitting: false,
			errorFields: {},
			// password change - current password value
			current_password: '',
			// password change - new password value
			new_password: '',
			// change password options: keep_existing | new
			password_option: 'keep_existing'
		};
	}

	static contextType = AuthContext;

	static get menu() {
		return {
			text: 'Account & Settings',
			link: '/account'
		};
	}

	componentDidMount() {
		this.dbUnsubscribe = firebase
			.firestore()
			.collection('accounts')
			.doc(this.context.account.id)
			.onSnapshot(doc => {
				const account = {
					id: doc.id,
					...doc.data()
				};
				this.setState({ account });
			});
	}

	componentWillUnmount() {
		if (typeof this.dbUnsubscribe === 'function') {
			this.dbUnsubscribe();
			this.dbUnsubscribe = null;
		}
	}

	handlePassword() {
		return e =>
			this.setState({
				password_option: e.target.value
			});
	}

	validateSave() {
		const errorFields = {};

		if (this.state.password_option === 'new') {
			if (!this.state.current_password) {
				errorFields.current_password = 'Enter your current password.';
			}
			if (!this.state.new_password) {
				errorFields.new_password = 'Your new password cannot be blank.';
			} else if (this.state.new_password.length < 6) {
				errorFields.new_password =
					'Your new password must contain at least 6 characters.';
			}
		}

		if (Object.keys(errorFields).length) {
			this.setState({ errorFields });
			return false;
		}
		return true;
	}

	render() {
		const { account, errorFields } = this.state;
		if (!account) {
			return <CircularProgress />;
		}

		return (
			<div>
				<AccountOverview account={account} selected='account' />

				<div id='body'>
					<div id='content'>
						<fieldset>
							<legend className='title'>
								<i className='fas fa-user'></i>
								General
							</legend>

							<table>
								<tbody>
									<tr>
										<td>{`Email address:\xa0`}</td>
										<td style={{ fontWeight: 'bold' }}>
											{this.context.user.email}
										</td>
									</tr>
									<tr>
										<td>{`Business name:\xa0`}</td>
										<td style={{ fontWeight: 'bold' }}>{account.name}</td>
									</tr>
								</tbody>
							</table>
						</fieldset>

						<hr />

						<fieldset>
							<legend className='title'>
								<i className='fas fa-lock'></i>
								Security
							</legend>

							<div className='split'>
								<div className='splitted'>
									<Field
										label='Password'
										type='select'
										id='account-password'
										selected={this.state.password_option}
										onChange={this.handlePassword()}
										disabled={this.state.submitting}
										options={[
											{
												value: 'keep_existing',
												label: 'Keep existing password'
											},
											{
												value: 'new',
												label: 'Change my password'
											}
										]}
									/>
								</div>
								<div className='splitted'>
									<div
										className={
											this.state.password_option === 'new' ? '' : 'hidden'
										}
									>
										<div>
											<Field
												label='Current password'
												type='password'
												id='account-password-current'
												disabled={this.state.submitting}
												onChange={e => {
													this.setState({
														current_password: e.target.value,
														errorFields: {
															...this.state.errorFields,
															current_password: null
														}
													});
												}}
												error={errorFields.current_password}
												required={true}
												value={this.state.current_password}
											/>
											<Field
												label='Enter a new password'
												type='password-strength'
												id='account-password-new'
												disabled={this.state.submitting}
												onChange={e => {
													this.setState({
														new_password: e.password,
														errorFields: {
															...this.state.errorFields,
															new_password: null
														}
													});
												}}
												error={errorFields.new_password}
												required={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</fieldset>

						<div id='cta'>
							<Submit
								label='Update'
								onClick={async () => {
									if (this.state.password_option !== 'new') {
										return;
									}

									if (!this.validateSave()) {
										return;
									}

									this.setState({ submitting: true });

									try {
										await this.changePassword();
									} catch (err) {
										Toast.fire({
											type: 'error',
											title: `Update failed. ${err.message}`
										});
										this.setState({
											submitting: false
										});
										return;
									}

									Toast.fire({
										type: 'success',
										title: 'Changes successfully saved'
									});

									this.setState({
										password_option: 'keep_existing',
										new_password: null,
										submitting: false
									});
								}}
								submitting={this.state.submitting}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async changePassword() {
		const user = firebase.auth().currentUser;
		// reauthenticate the user first
		const creds = firebase.auth.EmailAuthProvider.credential(
			user.email,
			this.state.current_password
		);
		await user.reauthenticateAndRetrieveDataWithCredential(creds);

		// if we reached here, the user's credentials are ok - change the user's password
		await user.updatePassword(this.state.new_password);
	}
}

export default Account;
