module.exports = {
  env: 'prod',

  /**
   * The domain used in the QR codes and payment pages
   */
  paymentDomain: 'zaura.com',

  firebaseConfig: {
    apiKey: 'AIzaSyAeYKIqmO3Am6hwtJQyEK0LizSN6VvtOfk',
    authDomain: 'sqrdot-payments.firebaseapp.com',
    databaseURL: 'https://sqrdot-payments.firebaseio.com',
    projectId: 'sqrdot-payments',
    storageBucket: 'sqrdot-payments.appspot.com',
    messagingSenderId: '3043873955',
    appId: '1:3043873955:web:98d3046f45da64ca831b16',
  },

  appBarColour: '#3f50b5',
};
