import React from 'react';
import OverviewColumn from '../Components/overview';
import Field from '../Components/field';

class PageOverview extends React.Component {
    
    render() {
        
        const { page, codes, transactions, selected } = this.props;
        
        let filter = null;
        if(selected === 'payments') {
        
        	filter = {
				title: 'Filter Payments',
				icon: '',
				fields: (
					<div>
						<Field 
							label="Date Range"
							type="text"
			                value="2019-07-15 - 2019-07-16"
						/>
						<Field 
							label="Zaura Code"
							type="select"
							options={codes.map(code => code.name)
								.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
								.map(name => ({
									value: name,
									label: name,
								}))
							}
						/>
					</div>
				)
			}
        }
        
        

        return (
        	<OverviewColumn 
	        	title={page.name}
				back={{
					url: '/pages', 
					label: 'Payment Pages'
				}}
				content="Here you can edit and view all details for your payment page."
				links={[
					{
						label: 'Page Details',
						url: `/pages/${page.id}`,
						selected: (selected === 'details' ? true : false)
					},
					{
						label: 'Zaura Codes',
						url: `/pages/${page.id}/codes`,
						counter: codes.length,
						selected: (selected === 'codes' ? true : false)
					},
					{
						label: 'Payments',
						url: `/pages/${page.id}/payments`,
						counter: transactions.length,
						selected: (selected === 'payments' ? true : false)
					}
				]}
				filter={filter}
	        />
		);
    }
}

export default PageOverview;