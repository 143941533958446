import React from 'react';
import moment from 'moment';
import AuthContext from '../AuthContext';
import firebase from '../../firebase';
import { Table, Thead, Th, Tr, Td, CaseInsensitive, Numeric } from "reactable";
import ReactTooltip from 'react-tooltip';
import OverviewColumn from '../Components/overview';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from '../../utils';

class Transactions extends React.Component {

    constructor(props) {
        super(props);
        this.dbUnsubscribe = [];
        this.state = {
            codes: new Map(), // <id, code name>
            pages: new Map(), // <id, page name>
            transactions: [],
        };
    }

    static contextType = AuthContext;

    static get menu() {
        return {
            text: 'Transactions',
            link: '/transactions',
            icon: 'fas fa-receipt'
        }
    }

    componentDidMount() {
        this.dbUnsubscribe[0] = firebase.firestore()
            .collection('transactions')
            .where('account', '==', this.context.account.id)
            .onSnapshot(snapshot => {
                let transactions = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                function getReadableDate(unix_time) {
                    const m = moment.unix(unix_time);
                    return {
                        sortable: m.format('YYYY-MM-DD HH:mm:ss'),  // 2019-02-01 13:14:15
                        uk: m.format('DD/MM/YYYY HH:mm:ss'),  // 01/02/2019 13:14:15
                        localised: m.format('dddd Do MMMM LTS'),    // Friday 1st February 1:14:15 PM
                    }
                }
                transactions = transactions.reduce((arr, t) => {
                    if (t.succeeded) {
                        // real stripe payment completed
                        arr.push({
                            amount: t.succeeded.amount_received / 100,
                            created: t.succeeded.created,
                            currency: {
                                code: t.succeeded.currency,
                                symbol: getCurrencySymbol(t.succeeded.currency),
                            },
                            date: getReadableDate(t.succeeded.created),
                            email: t.succeeded.receipt_email,
                            id: t.id,
                            livemode: t.succeeded.livemode,
                            pageid: t.paymentPage,
                            qrid: t.qrid,
                        })
                    } else if (t.payment && (!t.paymentIntentID || /^pi_yaytest/.test(t.paymentIntentID))) {
                        // older transactions used 'token', but we now use 'paymentMethod'
                        try {
                            const created = (t.payment.paymentMethod || t.payment.token).created;
                            const currencyCode = t.currency || 'gbp';
                            arr.push({
                                amount: t.amount,
                                created,
                                currency: {
                                    code: currencyCode,
                                    symbol: getCurrencySymbol(currencyCode),
                                },
                                date: getReadableDate(created),
                                email: t.payment.payerEmail,
                                id: t.id,
                                livemode: false,
                                pageid: t.paymentPage,
                                qrid: t.qrid,
                            })
                        } catch(err) {}
                    }
                    return arr;
                }, []);
                // sort the transactions most-recent-first
                transactions.sort((a,b) => b.created - a.created);
                this.setState({
                    transactions,
                })
            }
        );
        this.dbUnsubscribe[1] = firebase.firestore()
            .collection('pages')
            .where('account', '==', this.context.account.id)
            .onSnapshot(snapshot => {
                const pages = new Map();
                snapshot.docs.forEach(doc => pages.set(doc.id, doc.data().name));
                this.setState({
                    pages,
                });
            }
        );
        this.dbUnsubscribe[2] = firebase.firestore()
            .collection('codes')
            .where('account', '==', this.context.account.id)
            .onSnapshot(snapshot => {
                const codes = new Map();
                snapshot.docs.forEach(doc => codes.set(doc.id, doc.data().name));
                this.setState({
                    codes,
                });
            }
        );
    }

    componentWillUnmount() {
        for (let fn of this.dbUnsubscribe) {
            if (typeof fn === 'function') {
                fn();
            }
        }
        this.dbUnsubscribe = [];
    }
    
    componentDidUpdate() {
	   	ReactTooltip.rebuild();
	}

    render() {

/*         const filter_data = {
	        pages: [
                {
                    value: '',
                    label: 'All'
                },
                ...[...this.state.pages]
                    .sort((a,b) => caseInsensitiveSort(a[1], b[1]))
                    .map(
                        ([id,name]) => ({
                            value: id,
                            label: name,
                        }))
            ],
            codes: [
                {
                    value: '',
                    label: 'All'
                },
                ...[...this.state.codes]
                    .sort((a, b) => caseInsensitiveSort(a[1], b[1]))
                    .map(
                        ([id, name]) => ({
                            value: id,
                            label: name,
                        }))
            ],
        };
 */        
        return (
            <div>
			
				<OverviewColumn 
			    	title="Transactions"
                    content="This is where you will find details for all the transactions which have occured on your account."
                    /*
					filter={{
						title: 'Filter Transactions',
						icon: '',
						fields: (
							<div>
								<Field 
									label="Date Range"
									type="text"
					                value="2019-07-15 - 2019-07-16"
								/>
								<Field 
									label="Status"
									type="select"
					                options={[
						                {
						                    value: '',
						                    label: 'All'
						                },
						                {
						                    value: '???',
						                    label: 'Live'
						                },
						                {
						                    value: '???',
						                    label: 'Test'
						                }
						            ]}
								/>
								<Field 
									label="Payment Page"
									type="select"
                                    options={filter_data.pages}
								/>
								<Field 
									label="Zaura Code"
									type="select"
                                    options={filter_data.codes}
								/>
							</div>
						)
                    }}
                    */
					{...this.props}
			    />
			                    
				<div id="body">
			
					<div id="content">

		                <Table 
							className="data-table transactions"
							sortable={[
							    {
							        column: 'date',
							        sortFunction: CaseInsensitive
							    },
								    {
							        column: 'product',
							        sortFunction: CaseInsensitive
							    },
							    {
							        column: 'code',
							        sortFunction: CaseInsensitive
							    },
								{
							        column: 'email',
							        sortFunction: CaseInsensitive
							    },
							    {
							        column: 'amount',
							        sortFunction: Numeric
							    }
							]}
							defaultSort={{
								column: 'date', 
								direction: 'desc'
							}}
							itemsPerPage={20} 
							pageButtonLimit={5}
						>							
		                    <Thead>
	                            <Th column="date">Date</Th>
	                            <Th column="product">Product</Th>
	                            <Th column="code">Zaura Code</Th>
	                            <Th column="email">Email address</Th>
	                            <Th column="amount">Amount</Th>
		                    </Thead>
	                        {this.state.transactions.map(t => {
	                            const code = (t.qrid && this.state.codes.get(t.qrid)) || '';
	                            const page = (t.pageid && this.state.pages.get(t.pageid)) || '';

	                            return (
		                            <Tr key={t.id}>
		                                <Td column="date" value={t.date.sortable} className="nowrap">
		                                    <div>
			                                    <i 
													className={`status ${t.livemode ? '' : 'inactive'}`}
													data-tip={t.livemode ? 'Live' : 'Test'}
												/>
												<span data-tip={t.date.localised}>
			                                        {t.date.uk}
			                                    </span>
			                                </div>
		                                </Td>
		                                <Td column="product">{page}</Td>
		                                <Td column="code">{code}</Td>
		                                <Td column="email">{t.email || ''}</Td>
		                                <Td column="amount">
			                                <NumberFormat 
												value={(t.amount || 0).toFixed(2)} 
												displayType={'text'}
												thousandSeparator={true}
												prefix={t.currency.symbol}
											/>
			                            </Td>
		                            </Tr>
	                            )
	                        })}
		                </Table>
		                <ReactTooltip 
							multiline={true}
						/>
						{/* <div id="cta" className="overview">
							<button
								className="button"
				                onClick={() => alert('Coming soon') }
					        >
					            Export Data
					        </button>
						</div> */}
			        </div>
			    </div>
			</div>
        );
    }
}

export default Transactions;
