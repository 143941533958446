import React from 'react';
import { CircularProgress } from '@material-ui/core';

import AccountOverview from './overview';
import Submit from '../Components/submit';

import AuthContext from '../AuthContext';
import firebase from '../../firebase';
import Swal from 'sweetalert2'

class AccountStripe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            /** @type {null | 'perform-connect' | 'confirm-disconnect' | 'perform-disconnect'} */
            stripeState: null,
            submitting: false
        }
        this.dbUnsubscribe = null;
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.dbUnsubscribe = firebase.firestore().collection('accounts').doc(this.context.account.id).onSnapshot(
            doc => {
                const account = {
                    id: doc.id,
                    ...doc.data(),
                };
                this.setState({ account });
            }
        );
    }

    componentWillUnmount() {
        if (typeof this.dbUnsubscribe === 'function') {
            this.dbUnsubscribe();
            this.dbUnsubscribe = null;
        }
    }

    render() {
        const { account } = this.state;

        if (!account) {
            return <div>
                <CircularProgress  />
            </div>
        }
        return (
        	<div>
	        	<AccountOverview
	        		account={account}
	        		selected="stripe" 
			    />
			                    
				<div id="body">
			
					<div id="content">
			            
				        <div className="lead">
					        <p>Payments via your Zaura payment pages are processed by our selected partner, <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe</a>.</p>
					        <p>In order to accept payments, you need to connect your Zaura account with Stripe.</p>
					    </div>
			            
						{account.stripeAccount && !this.state.stripeState &&
	                     <fieldset className={`stripe-status ${account.stripeAccount ? 'connected' : 'disconnected'}`}>
							<legend className="title">
								<i className="fab fa-stripe-s"></i>
								Stripe Status
							</legend>
							
		                        <span>
			                        <i className="far fa-check-circle"></i>
			                        Connected to account <em>{account.stripeAccount.data.stripe_user_id}</em>
			                    </span>
						</fieldset>}
				    
					    <div id="cta">
							
							{account.stripeAccount  && <div id="stripe_form">
	                            <input type="hidden" name="accountID" value={account.id} />
	                            
	                            <Submit
	                                label="Disconnect Stripe"
	                                onClick={() => {
		                                
		                                Swal.fire({
											title: 'Are you sure?',
											text: "If you disconnect your Stripe account, you will no longer be able to receive payments until the account is connected again.",
											type: 'warning',
											showCancelButton: true,
											confirmButtonText: 'Yes, disconnect!',
											focusConfirm: false
										}).then((result) => {
											
											if (!result.value) {
												return; // user cancelled
											}

											this.setState({
												stripeState: 'perform-disconnect',
												submitting: true
											});	

											fetch(`/stripe/disconnect`, {
												method: 'POST',
												headers: {
													'Content-Type': 'application/json'
												},
												body: JSON.stringify({
													accountID: account.id
												})
											})
											.catch(err => { })
											.then(() => {
												this.setState({
													stripeState: null,
													submitting: false,
												});
											})
										});
									}}
									submitting={this.state.submitting}
	                            />
	                        </div>}
		                    
		                    {!account.stripeAccount && <form action="/stripe/onboard" method="POST" id="stripe_form">
			                    <input type="hidden" name="accountID" value={account.id} />
								
								<Submit 
		                            label="Connect Stripe"
		                            onClick={() => {
			                            this.setState({ stripeState: 'perform-connect', submitting: true })
			                            document.getElementById('stripe_form').submit();
			                        }}
		                            submitting={this.state.submitting}
		                        />
		                    </form>}
						</div>
					</div>
				</div>
			</div>
		);
    }
}

export default AccountStripe;
