import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Thead, Th, Tr, Td, Numeric } from 'reactable';
import Swal from 'sweetalert2';

import AuthContext from '../AuthContext';
import SplitButton from '../Components/split_button';

import axios from '../../config/axios';
import PageOverview from './overview';
import { makeQRCodeShareURL } from '../../data';
import { caseInsensitiveSort } from '../../utils';

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

class PaymentPageCodes extends React.Component {
	static contextType = AuthContext;

	render() {
		const { page, codes, transactions } = this.props;
		return (
			<div>
				<PageOverview
					page={page}
					codes={codes}
					transactions={transactions}
					selected='codes'
				/>

				<div id='body'>
					<div id='content'>
						<Table
							className='data-table codes actions'
							sortable={[
								{
									column: 'title',
									direction: 'asc',
									sortFunction: (a, b) =>
										caseInsensitiveSort(a.props.children, b.props.children)
								},
								{
									column: 'scans',
									sortFunction: Numeric
								},
								{
									column: 'payments',
									sortFunction: Numeric
								}
							]}
							defaultSort={{
								column: 'title',
								direction: 'asc'
							}}
							itemsPerPage={20}
							pageButtonLimit={5}
						>
							<Thead>
								<Th column='title'>Title</Th>
								<Th column='scans'>Scans</Th>
								<Th column='payments'>Payments</Th>
								<Th column='actions'>Actions</Th>
							</Thead>
							{codes
								.sort((a, b) => caseInsensitiveSort(a.name, b.name))
								.map(code => {
									const codeTransactions = transactions.filter(
										tx => tx.qrid === code.id
									);

									return (
										<Tr key={code.name}>
											<Td column='title'>
												<Link
													to={`/pages/${page.id}/codes/${code.id}/share`}
													data-tip='View code'
												>
													{code.name || 'Untitled'}
												</Link>
											</Td>
											<Td column='scans'>{code.scanCount || 0}</Td>
											<Td column='payments' value={transactions.length}>
												<Link
													to={`/pages/${page.id}/payments?code=${code.id}`}
													data-tip='View all transactions for this code'
												>
													{codeTransactions.length}
												</Link>
											</Td>
											<Td column='actions'>
												<SplitButton
													primary={{
														label: 'Edit',
														url: `/pages/${page.id}/codes/${code.id}`
													}}
													secondary={[
														{
															label: 'Download',
															href: makeQRCodeShareURL(
																this.context.account.urlname,
																code
															)
														},
														{
															label: 'Share',
															url: `/pages/${page.id}/codes/${code.id}/share`
														},
														{
															label: 'Payments ',
															url: `/pages/${page.id}/payments?code=${code.id}`
														},
														{
															label: 'Delete',
															className: 'warning',
															onClick: () => {
																Swal.fire({
																	title: 'Are you sure?',
																	text: `Deleting this Zaura Code will mean any future scans will fail. Are you sure you want to delete '${code.name}'?`,
																	type: 'warning',
																	showCancelButton: true,
																	confirmButtonText: 'Yes, delete it!',
																	focusConfirm: false
																}).then(result => {
																	if (result.value) {

																		axios.post('/code', {
																			action: 'delete',
																			data: [code.id],
																		}).then(res => {

																			Toast.fire({
																				type: 'success',
																				title: 'Zaura Code deleted'
																			});
																		}, err => {
																			Toast.fire({
																				type: 'warning',
																				title: 'Delete failed',
																				text: `Zaura Code delete failed. ${err.message}`,
																			})
																			.then(
																				res => {
																					Toast.fire({
																						type: 'success',
																						title: 'Zaura Code deleted'
																					});
																				},
																				err => {
																					Toast.fire({
																						type: 'warning',
																						title: 'Delete failed',
																						text: `Zaura Code delete failed. ${err.message}`
																					});
																				}
																			);
																		});
																	}
																});
															}
														}
													]}
												/>
											</Td>
										</Tr>
									);
								})}
						</Table>
						<div id="cta" className="overview">
							<Link to={`/pages/${page.id}/codes/create`} className="button">
								Add Zaura Code
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentPageCodes;
