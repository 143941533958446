import React from 'react';
import AuthContext from '../AuthContext';

import firebase from '../../firebase';

import OverviewColumn from '../Components/overview';
import PageOverview from './overview';
import Field from '../Components/field';
import Submit from '../Components/submit';
import { getAccountCurrency } from '../../utils';

function newPage() {
    return {
        name: '',
        notes: '',
        productID: '',
        description: '',
        prices: [],
        qrcodes: [],
        allowUserChosenAmount: false,
        mode: 'test',
    }
}

class PaymentPageDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: props.page || newPage(),
            pageSub: 'details',
            newPaymentAmount: '',
            errorFields: {},
            existing_page_names: [],
            preview: false,
            preview_open: false
        };
        this.current_page_name = props.page ? props.page.name : null;
        this.dbUnsubscribePages = null;        
    }
    
    togglePreview = () => {
        this.setState(state => ({ preview_open: !state.preview_open }));
    };

    static contextType = AuthContext;

    commitNewPaymentAmount() {
        const num = this.state.newPaymentAmount.replace(/[^\d.]/g, '');
        const amount = parseFloat(num);
        const prices = this.state.page.prices.slice();
        if (Number.isFinite(amount) && !prices.includes(amount) && amount >= 1) {
            prices.push(amount);
            prices.sort((a,b) => a-b);
        }
        this.setState({
            newPaymentAmount: '',
            page: {
                ...this.state.page,
                prices,
            },
        });
        this.dbUnsubscribePages = null;
    }

    componentDidMount() {
        this.dbUnsubscribePages = firebase.firestore()
            .collection('pages')
            .where('account', '==', this.context.account.id)
            .onSnapshot(snapshot => {
                const existing_page_names = snapshot.docs.map(doc => doc.data().name);
                this.setState({
                    existing_page_names,
                })
            });
    }

    componentWillUnmount() {
        if (typeof this.dbUnsubscribePages === 'function') {
            this.dbUnsubscribePages();
            this.dbUnsubscribePages = null;
        }
    }

    handleChange(which, property = 'value', clearedErrorField = '') {
        return e => this.setState(
            {
                page: {
                    ...this.state.page,
                    [which]: e.target[property],
                },
                errorFields: {
                    ...this.state.errorFields,
                    // when a field is edited, clear any error state
                    [clearedErrorField || which]: null,
                },
            });
    }

    validateNewPage(page) {
        // trim the free-text fields
        for (let i in Object.keys(page)) {
            if (typeof page[i] === 'string') {
                page[i] = page[i].trim();
            }
        }
        const errorFields = {};
        if (!page.name) {
            errorFields.name = 'Name cannot be blank.';
        }
        // case-insensitve duplicate name check (ignore if we are editing the page and the name is unchanged)
        const duplicate_name = this.state.existing_page_names.find(name => name.localeCompare(page.name, undefined, { sensitivity: 'base' }) === 0);
        const name_changed = page.name !== this.current_page_name;
        if (name_changed && duplicate_name) {
            errorFields.name = 'A page with the same name already exists. Choose a different name.';
        }
        if (!page.description) {
            errorFields.description = 'Product description cannot be blank.';
        }
        if (!page.prices.length && !page.allowUserChosenAmount) {
            errorFields.prices = 'At least one fixed amount must be specified or custom amounts enabled.';
        }
        if (page.mode === 'live') {
            if (this.context.account.noLiveMode) {
                errorFields.mode = 'Please contact Zaura support to enable live payments on your account.';
            } else if (!this.context.account.stripeAccount) {
                errorFields.mode = 'Live payments cannot be activated until a Stripe account is connected.';
            }
        }
        if (Object.keys(errorFields).length) {
            this.setState({ errorFields })
            return false;
        }
        return true;
    }

    render() {

		const { page, errorFields } = this.state;
		const currency = getAccountCurrency(this.context.account);
       
        const new_page = !page.id;
        return (
            <div>
				{page.id
				? 
	                <PageOverview 
						page={page}
						codes={this.props.codes}
						transactions={this.props.transactions}
						selected="details"
	                />
				:
					<OverviewColumn 
	                	title="Create Payment Page"
						back={{
							url: '/pages', 
							label: 'Payment Pages'
						}}
						content="Create a new payment page to start collecting payments from your customers!"
						{...this.props}
	                />
                }
                           
				<div id="body" className={this.state.preview ? 'preview' : ''}>
			
					<div id="content">

						<fieldset>
							<legend className="title">
								<i className="fas fa-pen"></i>
								General Details
							</legend>
							
							<Field 
								label="Name"
								type="text"
								id="page-name"
								required={true}
								value={page.name}
		                        onChange={this.handleChange('name')}
		                        error={errorFields.name}
		                        autoFocus={typeof window.orientation === "undefined"}
							/>
							
							<Field 
								label="Product Description"
								type="textarea"
								id="page-desc"
								required={true}
								value={page.description}
		                        onChange={this.handleChange('description')}
		                        error={errorFields.description}
							/>
								
							<div className="split">
								
								<div className="splitted">
									<Field 
										label="Product ID"
										type="text"
										id="page-prod-id"
										value={page.productID}
				                        onChange={this.handleChange('productID')}
				                        tooltip="This will be included in your transactions list, to allow easy lookup and reporting for individual products"
									/>
								</div>
								
								<div className="splitted">
									<Field 
										label="Status"
										type="select"
										id="payment-page-mode"
										selected={page.mode}
				                        onChange={this.handleChange('mode')}
				                        options={[
					                        {
												key: 'live',
						                        value: 'live',
						                        label: 'Live'
					                        },
					                        {
												key: 'test',
						                        value: 'test',
						                        label: 'Test - cards will not be charged'
					                        }
					                    ]}
					                    error={errorFields.mode}
					                    tooltip="Test mode allows your payments to be simulated, where your card will not be charged"
									/>
								</div>
							</div>
						</fieldset>
			  
						<hr/>
						
						<fieldset>
							<legend className="title">
								<i className="fas fa-credit-card"></i>
								Payment Amounts
							</legend>
	                    	                        
	                        <div className="split">
		                        
		                        <div className="splitted">
			                        			                        
			                        <Field 
										label={`Fixed Amount${page.prices.length === 1 ? '' : 's'}`}
										type="text-list"
										id="payment-page-mode"
										currency={currency}
					                    tooltip="These amounts can be selected by the user and not changed, allowing you a fixed pricing structure"
					                    placeholder="Enter amount"
			                            addAction={e => {
			                                this.commitNewPaymentAmount();
			                            }}
			                            deleteAction={(id) => {
                                            const prices = this.state.page.prices.filter(price => price !== id);
                                            this.setState({
                                                page: {
                                                    ...this.state.page,
                                                    prices,
                                                }
                                            });
                                        }}
			                            selected={page.prices}
			                            value={this.state.newPaymentAmount}
			                            onChange={e => {
			                                const m = e.target.value.match(/^\s*\D?\s*((\d{1,6}(\.\d{0,2})?)|(10{0,6}(\.0?0?)?))/);
			                                let newPaymentAmount = m ? m[0] : '';
			                                
			                                if(newPaymentAmount.startsWith(0)){
				                                newPaymentAmount = '';
			                                }
			                                
			                                this.setState({
			                                    newPaymentAmount,
			                                    errorFields: {
			                                        ...errorFields,
			                                        prices: null,
			                                    }
			                                });
			                            }}
			                            onCancel={e => {
				                            this.setState({
			                                    newPaymentAmount: '',
			                                    errorFields: {
			                                        ...errorFields,
			                                        prices: null,
			                                    }
			                                });
			                            }}
									/>
			                    </div>
			                    
			                    <div className="splitted">
				                    <Field 
										label="Custom Amount"
										type="select"
										selected={this.state.page.allowUserChosenAmount}
				                        onChange={this.handleChange('allowUserChosenAmount', 'value', 'prices')}
				                        options={[
					                        {
						                        value: '',
						                        label: 'Disabled'
					                        },
					                        {
						                        value: 'allow-user-amount',
						                        label: 'Enabled'
					                        }
					                    ]}
					                    tooltip={`To allow your user to pay any value (above ${currency.symbol}1), enable 'Custom Amount'`}
									/>
								</div>
							</div>
							
							{errorFields.prices && 
								<div className="error-reason">
									{errorFields.prices}
								</div>
							}
							
	                    </fieldset>	
	   
						<hr/>
						
						<fieldset>
							<legend className="title">
								<i className="fas fa-info"></i>
								Additional Information
							</legend>
							
							<Field 
								label="Notes"
								type="textarea"
								id="page-Notes"
								value={page.notes}
		                        onChange={this.handleChange('notes')}
							/>
						</fieldset>	
						
						<div id="cta">
							<Submit 
								label={new_page ? 'Save' : 'Update'}
								onClick={() => {
	                                const page = { ...this.state.page };
	                                if (!this.validateNewPage(page)) {
	                                    return;
	                                }
	                                this.props.onSave(page);
	                            }}
	                            submitting={this.props.submitting}
							/>
							
							{this.state.preview && 
								<button 
									id="preview_show"
									className="button secondary" 
									onClick={this.togglePreview}
								>
									Preview
								</button>
							}
						</div>					
					</div>
					
					{this.state.preview && 
						<div id="preview" className={this.state.preview_open ? 'open' : null}>
		                    <div id="iphone">
								<img src="/images/iphone.png" alt="iPhone Preview" />
								<div id="remote">
									<iframe title="iPhone Preview" src="https://www.zaura.com" frameBorder={0}></iframe>
								</div>
							</div>
							
							<button 
								id="preview_close"
								className="button" 
								onClick={this.togglePreview}
							>
								Close
							</button>
						</div>
					}
				</div>
            </div>
        );
    }
}

export default PaymentPageDetails;
