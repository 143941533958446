/**
 * Callback to pass to Array.sort() to sort strings alphabetically using case-insensitive comparison
 * @param {string} a
 * @param {string} b
 */
export function caseInsensitiveSort(a, b) {
    return a.localeCompare(b, undefined, { sensitivity: 'base' });
}

/**
 * Callback to pass to Array.sort() to sort strings reverse-alphabetically using case-insensitive comparison
 * @param {string} a
 * @param {string} b
 */
export function caseInsensitiveSortDesc(a, b) {
    return b.localeCompare(a, undefined, { sensitivity: 'base' });
}

/**
 * Formats a currency value with a fixed number of decimal places or a whole number if appropriate
 * @param {Number} amount
 * @returns {string}
 */
export function formatCurrency(amount, decimal_places=2) {
    return amount.toFixed(decimal_places).replace(/\.0+$/, '');
}

const currency_symbol_map = {
  AUD: '$',
  EUR: '€',
  GBP: '£',
  USD: '$',
};

/**
 * Converts a 3-letter currency code to its equivilent currency symbol.
 * If the currency code is not included in the map, the uppercase version of the code is returned.
 * @param {string} currency_code 3-letter code (gbp, usd, ...)
 */
export function getCurrencySymbol(currency_code) {
    if (typeof currency_code !== 'string') {
        return '';
    }
    const code = currency_code.toUpperCase();
    return currency_symbol_map[code] || code;
}

/**
 * Returns the currency code and symbol associated with an account.
 * @param {*} account 
 */
export function getAccountCurrency(account) {
    const default_currency = {
        code: 'gbp',
        symbol: '£',
    };
    return (account && account.currency) || default_currency;
}
