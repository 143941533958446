import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';

import { Typography, Button, CircularProgress, Input } from '@material-ui/core';
import AuthContext from '../AuthContext';
import axios from '../../config/axios';
import firebase from '../../firebase';

import AccountOverview from './overview';
import Field from '../Components/field';
import Submit from '../Components/submit';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom',
	showConfirmButton: false,
	timer: 5000
});

const styles = {
	noImage: {
		display: 'inline-block',
		border: '1px solid #ddd',
		borderRadius: '.3em',
		marginTop: '1em',
		padding: '.2em'
	},
	image: {
		maxHeight: '20em',
		maxWidth: '20em'
	}
};

/**
 * Converts image file data into a data URL, ensuring its size is < 300KB
 * @param {Blob} file
 * @returns {Promise<string>}
 */
function imageFileToDataURL(file) {
	if (!file || !/^image\/\w+/.test(file.type)) {
		return Promise.reject(
			new Error('The file does not contain a valid image.')
		);
	}
	return new Promise(resolve => {
		// load the image file into a reader
		const reader = new FileReader();
		reader.onload = fileLoaded;
		reader.readAsDataURL(file);

		// we allow up to a 300KB image, which means 400KB base64 length
		const maxDataURLLength = 400 * 1024;

		function fileLoaded(e) {
			const originalDataURL = e.target.result;
			if (originalDataURL.length <= maxDataURLLength) {
				// the original file is small enough already
				return resolve(originalDataURL);
			}
			// load the image itself so we can determine its original dimensions
			const img = new Image();
			img.onload = function() {
				imgLoaded(this);
			};
			img.src = e.target.result;
		}

		function imgLoaded(img) {
			const canvas = document.createElement('canvas');
			let scale = 1;
			// loop until we reach a reasonable size - because image data is non-linear,
			// scaling the image to reach a desired data length is done in steps to prevent
			// over-scaling.
			function resize() {
				const width = (img.width * scale) | 0;
				const height = (img.height * scale) | 0;
				// set the new canvas size
				canvas.width = width;
				canvas.height = height;
				// draw the original image into the canvas (at the new height and width)
				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0, width, height);
				// retrieve the data URL for the resized image
				const dataurl = canvas.toDataURL('image/png', 1);
				// have we reduced it enough?
				if (dataurl.length <= maxDataURLLength) {
					resolve(dataurl);
					return;
				}
				// update the scale and try again
				scale *= 0.9;
				setTimeout(resize, 0);
			}
			resize();
		}
	});
}

class AccountBusiness extends React.Component {
	dbUnsubscribe = null;

	constructor(props) {
		super(props);
		this.state = {
			account: null,
			logoUploadError: '',
			submitting: false,
			errorFields: {},
			/** Set to true if the company logo cannot be loaded and we should display a placeholder instead */
			showLogoPlaceholder: false,
			/** true if we are uploading a new company-logo */
			updatingLogo: false
		};
	}

	static contextType = AuthContext;

	componentDidMount() {
		this.dbUnsubscribe = firebase
			.firestore()
			.collection('accounts')
			.doc(this.context.account.id)
			.onSnapshot(doc => {
				const account = {
					id: doc.id,
					...doc.data()
				};
				this.setState({ account });
			});
	}

	componentWillUnmount() {
		if (typeof this.dbUnsubscribe === 'function') {
			this.dbUnsubscribe();
			this.dbUnsubscribe = null;
		}
	}

	handleChange(which) {
		return e =>
			this.setState({
				account: {
					...this.state.account,
					[which]: e.target.value
				},
				errorFields: {
					...this.state.errorFields,
					// when a field is edited, clear any error state
					[which]: null
				}
			});
	}

	validateSave(account) {
		// trim the free-text fields
		for (let i in Object.keys(account)) {
			if (typeof account[i] === 'string') {
				account[i] = account[i].trim();
			}
		}
		const errorFields = {};
		if (!account.name) {
			errorFields.name = 'Business name cannot be blank.';
		}
		if (Object.keys(errorFields).length) {
			this.setState({ errorFields });
			return false;
		}
		return true;
	}

	render() {
		// const { classes, account } = this.props;
		const { classes } = this.props;
		const {
			account,
			errorFields,
			showLogoPlaceholder,
			updatingLogo
		} = this.state;
		if (!account) {
			return <CircularProgress />;
		}
		// the logo url always has the current time appended to the query to force a refresh on each state change
		const logo_url = `https://firebasestorage.googleapis.com/v0/b/${
			firebase.app().options['storageBucket']
		}/o/accounts%2F${
			this.context.account.id
		}%2Fcompany-logo?alt=media&u=${Date.now()}`;
		return (
			<div>
				<AccountOverview account={account} selected='business' />

				<div id='body'>
					<div id='content'>
						<fieldset>
							<legend className='title'>
								<i className='fas fa-pen'></i>
								General
							</legend>

							<div className='split'>
								<div className='splitted'>
									<Field
										label='Business Name'
										type='text'
										id='account-name'
										required={true}
										value={account.name}
										onChange={this.handleChange('name')}
										autoFocus={typeof window.orientation === 'undefined'}
										error={errorFields.name}
									/>
								</div>
								{/* <div className='splitted'>
									<Field
										label='Website'
										type='text'
										id='account-website'
										value={account.website}
										onChange={this.handleChange('website')}
									/>
								</div> */}
							</div>
							<div className='split'>
								<div className='splitted'>
									<Field
										label='Support Email Address'
										type='email'
										id='account-email'
										value={account.support_email}
										onChange={this.handleChange('support_email')}
									/>
								</div>
								<div className='splitted'>
									<Field
										label='Support Telephone'
										type='telephone'
										id='account-phone'
										value={account.support_phone}
										onChange={this.handleChange('support_phone')}
									/>
								</div>
							</div>
						</fieldset>
						<div id='cta'>
							<Submit
								label='Save'
								onClick={() => {
									if (!this.validateSave(account)) {
										return;
									}

									this.setState({ submitting: true });

									const {
										name,
										website = '',
										support_email = '',
										support_phone = ''
									} = this.state.account;

									axios({
										url: '/account/update',
										method: 'POST',
										data: {
											uid: this.context.user.uid,
											accountID: this.context.account.id,
											name,
											website,
											support_email,
											support_phone
										}
									}).then(
										res => {
											Toast.fire({
												type: 'success',
												title: 'Changes successfully saved'
											});

											this.setState({ submitting: false });
										},
										err => {
											Toast.fire({
												type: 'error',
												title: `Account update failed. ${err.message}`
											});

											this.setState({ submitting: false });
										}
									);
								}}
								submitting={this.state.submitting}
							/>
						</div>

						<hr />

						<fieldset>
							<legend className='title'>
								<i className='fas fa-image'></i>
								Branding
							</legend>

							<p>
								Your business name and logo will be displayed on payment pages
								to identify your brand.
							</p>

							<div>
								{updatingLogo && (
									<i
										className='fas fa-circle-notch fa-spin'
										style={{
											fontSize: '5em',
											color: 'rgba(61, 151, 216, 0.3)'
										}}
									/>
								)}
								{!updatingLogo && (
									<div className={classes.noImage}>
										{showLogoPlaceholder ? (
											<AddAPhotoRoundedIcon
												alt-text='Company logo'
												style={{ margin: '2em' }}
											/>
										) : (
											<img
												className={classes.image}
												alt='Company logo'
												src={logo_url}
												onError={() => {
													// if we failed to load the image, show the placeholder
													// this can happen if no image has been uploaded (or the image has been deleted)
													this.setState({
														showLogoPlaceholder: true
													});
												}}
											/>
										)}
									</div>
								)}
							</div>
							<form style={{ position: 'absolute' }}>
								<Input
									style={{ opacity: 0 }}
									id='logo-file-input'
									type='file'
									onChange={e => {
										// @ts-ignore Property 'files' does not exist on type
										const file = e.target.files[0];
										if (!file) {
											return;
										}
										imageFileToDataURL(file)
											.then(dataurl => {
												const ref = firebase
													.storage()
													.ref(
														`accounts/${this.context.account.id}/company-logo`
													);
												return ref
													.putString(dataurl, 'data_url', {
														customMetadata: {
															account: this.context.account.id
														}
													})
													.then(() => {
														this.setState({
															showLogoPlaceholder: false,
															updatingLogo: false
														});
													});
											})
											.catch(err => {
												const errstr = `Image upload failed. ${err.message}`;
												this.setState({
													logoUploadError: errstr,
													updatingLogo: false
												});
											});
										this.setState({
											logoUploadError: '',
											updatingLogo: true
										});
									}}
								/>
							</form>
							{!updatingLogo && (
								<Button
									color='primary'
									onClick={() =>
										document.getElementById('logo-file-input').click()
									}
								>
									{'Upload new image'}
								</Button>
							)}
							{this.state.logoUploadError && (
								<Typography variant='caption' style={{ color: '#c00' }}>
									{this.state.logoUploadError}
								</Typography>
							)}
						</fieldset>
					</div>
				</div>
			</div>
		);
	}
}

AccountBusiness.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountBusiness);
