import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthContext from '../AuthContext';
import axios from '../../config/axios';

import OverviewColumn from '../Components/overview';
import QRCodeOverview from './overview';
import Field from '../Components/field';
import Submit from '../Components/submit';

class QRCodeDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: props.page,
			code: props.code,
			/**
			 * the current/updated name of the QR code
			 */
			name: props.code ? props.code.name : '',
			/**
			 * error message returned when saving fails
			 */
			errorMessage: '',
			/**
			 * true when we are attempting to save changes to the code
			 */
			saving: false
		};
	}

	static contextType = AuthContext;

	render() {
		const { page, code } = this.state;

		let new_code = true;

		if (code && code.id) {
			new_code = false;
		}

		return (
			<div>
				{!new_code && (
					<QRCodeOverview page={page} code={code} selected='details' />
				)}

				{new_code && (
					<OverviewColumn
						title='Add Zaura Code'
						back={{
							url: `/pages/${page.id}/codes`,
							label: `Zaura Codes for ${page.name}`
						}}
						content='Add a new Zaura Code to use on your payment page.'
						{...this.props}
					/>
				)}

				<div id='body'>
					<div id='content'>
						<fieldset>
							<legend className='title'>
								<i className='fas fa-pen'></i>
								General Details
							</legend>
							<Field
								label='Name'
								type='text'
								required={true}
								onChange={e => {
									this.setState({ name: e.target.value });
								}}
								value={this.state.name}
								// disable editing while we are saving
								disabled={this.state.saving}
								// don't autoFocus on mobile (automatic keyboard popup is jarring)
								autoFocus={typeof window.orientation === 'undefined'}
							/>
						</fieldset>

						<div id='cta'>
							<Submit
								label={new_code ? 'Save' : 'Update'}
								onClick={() => {
									if (!this.state.name) {
										return;
									}
									this.setState({
										errorMessage: '',
										saving: true
									});
									axios
										.post('/code', {
											action: new_code ? 'add' : 'update',
											data: {
												account: this.context.account.id,
												name: this.state.name,
												notes: '',
												paymentPage: page.id
											}
										})
										.then(
											() => {
												// navigate back to the payment page codes list
												this.props.history.push(`/pages/${page.id}/codes`);
											},
											err => {
												this.setState({
													errorMessage: err.message,
													saving: false
												});
											}
										);
								}}
								submitting={this.state.saving}
							/>
						</div>
						<div
							style={{
								color: '#a33',
								marginTop: '1em'
							}}
						>
							{this.state.errorMessage}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(QRCodeDetails);
