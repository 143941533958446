const config = require('./config');

exports.makeQRCodeURL = (account, id) => (account && id) ? `https://${config.paymentDomain}/${account}/c/${id}` : null;

exports.makeQRCodeShareURL = (urlname, code) => {
    if (!urlname || !code || !code.id) {
        return null;
    }
    // the share URL includes a date field to ensure the URL is unique.
    // - this is necessary because LinkedIn caches shared images based upon the share URL path. If the data associated
    // with the share changes (the payment page, for example), we must change the URL to force LinkedIn to
    // request a new image, title, description, etc.
    const share_url = `https://${config.paymentDomain}/${urlname}/share/${code.id}/${Date.now()}`;
    return share_url;
}

exports.makePaymentPageURL = (account,id) => (account && id) ? `https://${config.paymentDomain}/${account}/p/${id}` : null;
