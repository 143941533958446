import React from 'react';
import { Link } from 'react-router-dom';

class SplitButton extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
	        toggleOver: false,
	        subOver: false,
            subOpen: false
        };
    }
    
    elmOver = (type, status) => {
        this.setState(state => ({ [type]: status }));
    };
    
    generateLink = (link, className) => {
	    
	    if(link.className){
		    className = `${link.className ? link.className : ''} ${link.className}`;
		}
	    
	    if(!link.url){
		    
		    let href = '#';
		    let target = '_parent';
		    
		    if(link.href){
			    href = link.href;
			    target = '_blank';
		    }
		    
		    return (
			    <a 
				    href={href} 
					target={target}
					onClick={link.onClick ? () => { link.onClick() }: null}
					className={className}
				>                      
	                {link.label}
	            </a>
		    );
	    }
	    
	    return (
		    <Link 
			    to={link.url} 
				onClick={link.onClick ? () => { link.onClick() }: null}
				className={className}
			>                      
                {link.label}
            </Link>
	    );
    }
    
    render() {	    
		
        return (
            <div className={`split-button ${(this.state.toggleOver || this.state.subOver) ? 'open' : ''}`}>
				{this.generateLink(this.props.primary, 'primary')}
								
				<span className="toggle" onMouseEnter={() => { this.elmOver('toggleOver', true) }} onMouseLeave={() => { this.elmOver('toggleOver', false) }} onClick={() => { if(this.state.toggleOver){ this.elmOver('toggleOver', false); }else{ this.elmOver('toggleOver', true); } }} />

				<ul onMouseEnter={() => { this.elmOver('subOver', true) }} onMouseLeave={() => { this.elmOver('subOver', false) }}>
					
					{this.props.secondary.map(link => (
                        <li key={link.label}>
                            {this.generateLink(link)}
						</li>
					))}
				</ul>
			</div>
        );
    }
}
export default SplitButton;