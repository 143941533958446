import React, { Component } from 'react';
import Main from './Main';
import PrintableCode from './PrintableCode';
import Welcome from './Welcome';
import auth from './auth';
import AuthContext from './Main/AuthContext';

class App extends Component {

  state = {
    authState: null,
    printdata: PrintableCode.decodePrintURLPath(new URLSearchParams(window.location.search)),
  }

  /** @type {Function|null} */
  disconnectAuthMonitor = null;

  componentDidMount() {
    this.disconnectAuthMonitor = auth.monitorLoginChanges(
      authState => {
        this.setState({ authState });
      }
    );
  }

  componentWillUnmount() {
    if (this.disconnectAuthMonitor) {
      this.disconnectAuthMonitor();
      this.disconnectAuthMonitor = null;
    }
  }

  render() {
    if (this.state.printdata) {
      const {urlname, code, name} = this.state.printdata;
      return <PrintableCode urlname={urlname} code={code} name={name} />
    }

    const account = this.state.authState && this.state.authState.account;
    const user = this.state.authState && this.state.authState.user;
    return (
      <div className="App">
          {/* Don't show anything until we've received at least one auth callback */}
          {!this.state.authState && <div/>}
          {/* Show the main page if we have an account */}
          {this.state.authState && account && user &&
            // @ts-ignore JSX element type 'AuthContext' does not have any construct or call signatures.
            <AuthContext.Provider value={{user,account}}>
              <Main />
            </AuthContext.Provider>}
          {/* Show the login page if we don't have an account */}
          {this.state.authState && !account && <Welcome />}
      </div>
    );
  }
}

export default App;
